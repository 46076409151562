import { AppState } from '../index'
import { useSelector, useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { accountList, accountInfo, wrongNetwork, crossChainList, totalCount, skuCrossChainList,skuTotalCount } from './actions'
import { AccountInfo } from './types'

export function useAccountInfo(): AccountInfo {
  return useSelector((state: AppState) => state.wallet.accountInfo)
}

export function useAccountInfoUpdate(): (info: AccountInfo) => void {
  const dispatch = useDispatch()
  return useCallback((info: AccountInfo) => dispatch(accountInfo({accountInfo: info})), [dispatch])
}

export function useAccountList(): any {
  return useSelector((state: AppState) => state.wallet.accountList)
}

export function useAccountListUpdate(): (list: any) => void {
  const dispatch = useDispatch()
  return useCallback((list) => dispatch(accountList({accountList: list})), [dispatch])
}

export function useWrongNetwork(): boolean {
  return useSelector((state: AppState) => state.wallet.wrongNetwork)
}

export function useWrongNetworkUpdate(): (wrong: boolean) => void {
  const dispatch = useDispatch()
  return useCallback((wrong: boolean) => dispatch(wrongNetwork({wrongNetwork: wrong})), [dispatch])
}

export function useCrossChainList(): any {
  return useSelector((state: AppState) => state.wallet.crossChainList)
}

export function useSkuCrossChainList(): any {
  return useSelector((state: AppState) => state.wallet.skuCrossChainList)
}

export function useCrossChainListUpdate(): (info: any) => void {
  const dispatch = useDispatch()
  return useCallback((info: any) => dispatch(crossChainList({crossChainList: info})), [dispatch])
}

export function useSkuCrossChainListUpdate(): (info: any) => void {
  const dispatch = useDispatch()
  return useCallback((info: any) => dispatch(skuCrossChainList({skuCrossChainList: info})), [dispatch])
}

export function useTotalCount(): any {
  return useSelector((state: AppState) => state.wallet.totalCount)
}

export function useSkuTotalCount(): any {
  return useSelector((state: AppState) => state.wallet.skuTotalCount)
}

export function useTotalCountUpdate(): (info: any) => void {
  const dispatch = useDispatch()
  return useCallback((info: any) => dispatch(totalCount({totalCount: info})), [dispatch])
}

export function useSkuTotalCountUpdate(): (info: any) => void {
  const dispatch = useDispatch()
  return useCallback((info: any) => dispatch(skuTotalCount({skuTotalCount: info})), [dispatch])
}