import React, { Suspense, useState, useEffect, useCallback } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import { initApi } from '../utils/apiUtils'
import { ToastContainer, Slide } from 'react-toastify';
import { loadAccount } from '../utils/AccountUtils';
import { useWrongNetworkUpdate } from '../state/wallet/hooks'

import { useApiConnectedUpdate, useApiInited, useApiInitedUpdate } from '../state/api/hooks'
import { useAccountInfo, useAccountInfoUpdate, useAccountListUpdate } from '../state/wallet/hooks'

import AuctionHeader from '../components/AuctionHeader'

import HomePage from './homePage'
import SakuraHomePage from './sakuraHomePage'
import SelectAccountDialog from "../components/SelectAccountDialog/index";
import { DOWNLOAD_WALLET_ERL } from '../constants'
import CloverBg from '../assets/images/clover-bg.jpg'
import SakuraBg from '../assets/images/sakura-bg.jpg'
const AppWrapper = styled.div<{selectClover: boolean}>`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: stretch;
  //height: 100vh;
  min-height: 650px;
  min-width: 1200px;
  background-image: ${({ selectClover }) => (selectClover ? `url(${CloverBg})` : `url(${SakuraBg})`)};;
  background-size: 100% 368px;
  background-repeat: no-repeat;
  overflow: auto;
  @media (max-width: 576px){
    max-width: 100%;
    min-width: unset;
  }
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding-top: 0;
  `};

  z-index: 1;
`;

export default function App(): React.ReactElement {
  const apiInited = useApiInited()
  const apiInitedUpdate = useApiInitedUpdate()
  const updateApiConnected = useApiConnectedUpdate()
  const accountInfo = useAccountInfo();

  const onApiInited = useCallback(() => {
    apiInitedUpdate(true)
    updateApiConnected(true)
  }, [apiInitedUpdate, updateApiConnected])

  const updateAccountList = useAccountListUpdate()
  const updateAccountInfo = useAccountInfoUpdate()
  const updateWrongNetwork = useWrongNetworkUpdate()

  const [hasWallet, setHasWallet] = useState(false)
  const [connectWallet, setConnnectWallet] = useState(false)
  const [openSelectAccount, setOpenSelectAccount] = useState(false)
  const [selectClover, setSelectClover] = useState(true)
  const initPolkaApi = useCallback(async (selectClover) => {
    await initApi(onApiInited, () => {updateApiConnected(true)}, () => {updateApiConnected(false)},selectClover)
  }, [onApiInited, updateApiConnected])

  useEffect(() => {
    initPolkaApi(selectClover)
  }, [initPolkaApi,selectClover])
  
  useEffect(() => {
    const hash = window.location.hash;
    if( hash === '#/sakuraHomePage') {
      setSelectClover(false);
      document.body.style.backgroundColor = '#1B2733';
    } else {
      setSelectClover(true);
      document.body.style.backgroundColor = '#FCFCFC';
    }
  }, [])

  const handleConnectWallet = useCallback(async () => {
    if (!apiInited) {
      initPolkaApi(selectClover)
    }
    if (connectWallet && accountInfo.address === "") {
      setOpenSelectAccount(true)
      return
    }
    if (connectWallet && accountInfo.address !== "") {
      return
    }
    const result = await loadAccount(undefined, updateAccountList, updateAccountInfo, updateWrongNetwork);
    if (result === 'notFoundWallet') {
      setHasWallet(false)
      window.open(DOWNLOAD_WALLET_ERL)
      return
    } else {
      setHasWallet(true)
    }
    if (result === 'ok') {
      setConnnectWallet(true)
      setOpenSelectAccount(true)
    }
    if (result === 'Select success') {
      setConnnectWallet(true)
    }
  }, [apiInited, connectWallet,selectClover, initPolkaApi, updateWrongNetwork, updateAccountInfo, accountInfo.address, updateAccountList]);

  return (
    <Suspense fallback={null}>
      <HashRouter>
        <AppWrapper selectClover = {selectClover}>
          <AuctionHeader 
            handleConnectWallet={handleConnectWallet} 
            openSelectAccount={() => setOpenSelectAccount(true)}
            changeSelectCloverStatus={(status: boolean) => {
              setSelectClover(status)
            }}  
            hasWallet={hasWallet} 
            selectClover ={selectClover}/>
          <BodyWrapper>
            <Switch>
              <Route exact strict path="/" component={HomePage} />
              <Route exact strict path="/homePage" component={HomePage} />
              <Route exact strict path="/sakuraHomePage" component={SakuraHomePage} />
            </Switch>
          </BodyWrapper>
          <ToastContainer
            position="top-center"
            autoClose={3000}
            transition={Slide}
            React-toastify
            hideProgressBar={true}/>
          <SelectAccountDialog
              open={openSelectAccount}
              handleClose={() => setOpenSelectAccount(false)}
          />
        </AppWrapper>
      </HashRouter>
    </Suspense>
  );
}
