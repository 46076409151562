import React, { useState } from 'react'
import './index.scss'
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '../../assets/images/dialog_close.svg'
import LoadingIcon from '../../assets/images/loading.svg'
import RemindIcon from '../../assets/images/remind_icon.svg'
import { checkAddressByFormat } from '../../utils/index'

export default function ClaimDialog(props) {
    const { handleClose, open, handleClaim, option, txHash, toChain, id, isSakura} = props;
    const [toAddress, setToAddress] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)
    const symbolName = isSakura ? 'SKU' : 'CLV'
    return (
        <Dialog className="claim-dialog" onClose={() => handleClose()} aria-labelledby="customized-dialog-title" open={open}>
            <div className="dialog-wrapper">
                <div>
                    <div className="dialog-header">
                        <h3>Claim Your {symbolName}</h3>
                        <img width={16} height={16} src={CloseIcon} alt="" onClick={() => handleClose()}/>
                    </div>
                    <div className="dialog-content">
                        <input
                            type="text"
                            placeholder={`Input your address to claim your ${symbolName}`}
                            onChange={(e) => {
                                setToAddress(e.target.value)
                                if (!checkAddressByFormat(e.target.value, toChain)) {
                                    setError('Invalid address')
                                } else {
                                    setError('')
                                }
                            }}
                        />
                        {error !== '' && <div className="error-msg">{error}</div>}

                        <div className="remind">
                            <div className="remind-item">
                                <img style={{marginLeft: '10px'}} width={24} height={24} src={RemindIcon} alt="" />
                                <span>Please make sure your {symbolName} address is correct. Otherwise you’ll lost your token forever.</span>
                            </div>
                            <div className="remind-item">
                                <img style={{marginLeft: '10px'}} width={24} height={24} src={RemindIcon} alt="" />
                                <span>Only <span>CLV Wallet</span> supported by now.</span>
                            </div>
                        </div>
                    </div>
                    <button
                        className= {`${isSakura ? 'isSakura' : ''} claim-btn`}
                        disabled={!toAddress || error !== ''}
                        onClick={loading ? null : () => {
                            setLoading(true)
                            handleClaim(option, txHash, toAddress, id)
                        }}
                    >
                        {loading ? <img height={30} width={30} src={LoadingIcon} alt=""/> : 'Claim'}
                    </button>
                </div>
            </div>
        </Dialog>
    )
}
