import React, {useCallback, useState} from 'react'
import './index.scss'
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '../../assets/images/dialog_close.svg'
import CopyIcon from "../../assets/images/copy_icon.svg";
import CopyHover from "../../assets/images/copy_hover.svg";
import LinkIcon from "../../assets/images/link_icon.svg";
import LinkHover from "../../assets/images/link_hover.svg";
import { EXPLORER_URL } from '../../constants'

import _ from 'lodash'
import copyToClipboard from 'copy-to-clipboard'
import { getAddress } from "../WalletComp/utils";
import {useAccountList, useAccountInfoUpdate, useAccountInfo} from '../../state/wallet/hooks'
import { selectAccount } from '../../utils/AccountUtils'
import { BlackTooltip } from '../BlackTooltip'

export default function SelectAccountDialog(props) {
    const { handleClose, open } = props;
    const currentAccount = useAccountInfo()
    const accountList = useAccountList()
    const updateAccountInfo = useAccountInfoUpdate()
    const [copyText, setCopyText] = useState('Click to copy')

    const handleSelectAccount = useCallback(async (account) => {
        try {
            await selectAccount(account, updateAccountInfo)
            handleClose()
        } catch (e) {
            console.log(e)
        }
    }, [updateAccountInfo, handleClose])

    return (
        <Dialog className="select-account-dialog" onClose={() => handleClose()} aria-labelledby="customized-dialog-title" open={open}>
            <div className="dialog-wrapper">
                <div>
                    <div className="dialog-header">
                        <h3>Your Wallet</h3>
                        <img width={16} height={16} src={CloseIcon} alt="" onClick={() => handleClose()}/>
                    </div>
                    <div className="dialog-content">
                        {accountList.length > 0 && (
                            _.map(accountList, (account, index) => (
                                <div key={index} className={`${currentAccount && currentAccount.address === account.address && 'selected'} account-list-item`} onClick={() => handleSelectAccount(account)}>
                                    <span>{account.name}</span>
                                    <div className="account-list-item-right">
                                        <span>{getAddress(account.address)}</span>
                                        <BlackTooltip
                                            arrow
                                            title={copyText}
                                        >
                                            <img
                                                src={CopyIcon}
                                                alt=""
                                                onMouseEnter={(e) => {
                                                    e.stopPropagation()
                                                    setCopyText('Click to copy')
                                                    e.target.setAttribute('src', CopyHover)
                                                }}
                                                onMouseLeave={(e) => {
                                                    e.stopPropagation()
                                                    e.target.setAttribute('src', CopyIcon)
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    copyToClipboard(account.address)
                                                    setCopyText('Copied!')
                                                }}
                                            />
                                        </BlackTooltip>
                                        <img
                                            src={LinkIcon}
                                            alt=""
                                            onMouseEnter={(e) => {
                                                e.stopPropagation()
                                                e.target.setAttribute('src', LinkHover)
                                            }}
                                            onMouseLeave={(e) => {
                                                e.stopPropagation()
                                                e.target.setAttribute('src', LinkIcon)
                                            }}
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                window.open(`${EXPLORER_URL}${account.address}`)
                                            }}
                                        />
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </Dialog>
    )
}
