import {web3Accounts, web3Enable, web3FromAddress} from '@polkadot/extension-dapp';
import { InjectedAccountWithMeta } from '@polkadot/extension-inject/types';
import keyring from '@polkadot/ui-keyring';
import _ from 'lodash'
import { createAccountInfo } from '../components/WalletComp/utils'
import { AccountInfo, TokenAmount } from '../state/wallet/types';
import BigNum from '../types/bigNum'
import { api } from './apiUtils'
import { originName, SERVICE_ADDRESS, SERVICE_SAKURA_ADDRESS} from '../constants'
import moment from 'moment';
import { getRequestTime, getDuration } from './index'

let keyringInited = false
declare global {
  interface Window {
      send:any;
      clover:any;
  }
}

export interface WalletType {
  name: string,
  showName: string,
  icon: string
}

export const supportedWalletTypes = [{
    name: 'Clover Wallet',
    showName: 'Clover',
    icon: require('../assets/images/icon-clover.svg')
  }];

export function getAddress (addr: string): string {
  if (_.size(addr) < 17) {
    return addr
  }

  const prefix = addr.substring(0, 11)
  const suffix = addr.substring(_.size(addr) - 4, _.size(addr))

  return `${prefix}..${suffix}`
}

export async function loadAllTokenAmount(addr: string): Promise<TokenAmount[]|null> {
  const rawApi = api.getApi();
  const { parentHash } = await rawApi.rpc.chain.getHeader();
  const {
    data: { free: balance },
  } = await rawApi.query.system.account.at(parentHash, addr);
  return [{
    tokenType: {
      id: -1,
      name: ''
    },
    amount: balance.toString(),
    amountBN: BigNum.fromBigNum(balance.toString()).toSerizableBigNum()
  }];
}

function isCloverWallet(injectedWallet: any) {
  return injectedWallet.name === 'clover'
}

function invalidWalletNetwork(allAccounts: InjectedAccountWithMeta[]): boolean {
  if (!keyringInited) {
    keyring.loadAll({ ss58Format: 42 }, allAccounts);
    keyringInited = true
  }
  const accounts = keyring.getAccounts();
  const addrs = _.map(accounts, (acc) => acc.address)

  return _.some(allAccounts, (acc) => !_.includes(addrs, acc.address))
}

export async function loadAccount(wallet: WalletType | undefined, updateAccountList: any,
  updateAccountInfo: (info: AccountInfo) => void, updateWrongNetwork: (wrong: boolean) => void): Promise<string> {
  const injected = await web3Enable(originName);

  window.send && window.send("log", injected)
  if (!injected.length) {
    return "notFoundWallet";
  }

  /* eslint-disable  @typescript-eslint/no-explicit-any */
  // const mathWallet = _.find(injected, (w: any) => w.isMathWallet === true)
  // const polkadotWallet: any = _.find(injected, (w: any) => isPolkadotWallet(w))
  const cloverWallet: any = _.find(injected, (w: any) => isCloverWallet(w))
  if (_.isEmpty(cloverWallet)) {
    return "notFoundWallet";
  }

  let allAccounts = await web3Accounts();

  window.send && window.send("log", allAccounts)

  allAccounts = _.filter(allAccounts, acc => acc.meta?.source === 'clover')

  if (!allAccounts.length) {
    return "addWallet"
  }

  if (invalidWalletNetwork(allAccounts)) {
    updateWrongNetwork(true)
    return "change to valid network"
  }

  updateAccountList(
      _.map(allAccounts, account => {
        return {
          address: account.address,
          name: account.meta?.name?? '',
          walletName: _.get(wallet, 'name', '')
        }
      })
  )

  if (allAccounts.length === 1) {
    const obj = {
      address: allAccounts[0].address,
      name: allAccounts[0].meta?.name?? '',
      walletName: _.get(wallet, 'name', '')
    }
    await selectAccount(obj, updateAccountInfo)
    return 'Select success'
  }

  return 'ok'
}

export async function selectAccount(account: any, updateAccountInfo: (info: AccountInfo) => void) {
  const { address, name, walletName } = account
  const tokenAmounts = await loadAllTokenAmount(address)
  if (tokenAmounts === null) {
    return "addWallet"
  }
  const info = createAccountInfo(address, name, walletName, tokenAmounts ?? [], [])
  updateAccountInfo(info)
}

const getDurationWithMinted = (txn: any, diffTime: number, claimDuration: any) => {
  let duration
  if (txn.chain_id && txn.minted && !txn.mint_time) {
    duration = '-'
  } else {
    duration = diffTime <= 0 ? '1 sec' : getDuration(claimDuration)
  }
  return duration
}

export async function getCrossChainData(currentPage: number, pageSize: number, updateCrossChainList: any, updateTotalCount: any, isSakura: boolean = false) {
  const allCount = await window.fetch(`${ isSakura ? SERVICE_SAKURA_ADDRESS : SERVICE_ADDRESS}statistic/total`, {
    method: 'GET',
  }).then((httpResponse) => httpResponse.json())

  const crossChains = await window.fetch(`${isSakura ? SERVICE_SAKURA_ADDRESS : SERVICE_ADDRESS}records?pageNum=${currentPage}&pageSize=${pageSize}`, {
    method: 'GET',
  }).then((httpResponse) => httpResponse.json())

  const modifiedCrossChains = crossChains.map((txn: any) => {
    const burnTime = moment(txn.burn_time);
    const nowTime = moment(new Date());
    const burnDuration = moment.duration(nowTime.diff(burnTime));
    const burnSeconds = Math.round(burnDuration.asSeconds());
    let claimTime
    if (txn.chain_id || txn.from_chain !== 'Clover') {
      claimTime = moment(txn.mint_time ?? new Date());
    } else {
      claimTime = moment(txn.claim_time ?? new Date());
    }
    const diffTime = claimTime.diff(burnTime);
    const claimDuration = moment.duration(diffTime);
    const claimSeconds = Math.round(moment.duration(nowTime.diff(claimTime)).asSeconds());
    
    return {
      requestTime: getRequestTime(txn.burn_time, burnSeconds),
      requestTimeByCalandar: burnTime.utc().format('YYYY-MM-DD HH:mm:ss'),
      duration: getDurationWithMinted(txn, diffTime, claimDuration),
      claimRequestTime: txn.claim_time ? getRequestTime(txn.claim_time, claimSeconds) : null,
      confirmations: burnSeconds > 300 ? '15/15' : null,
      ...txn
    };
  });

  updateTotalCount({ total_count: allCount.total_txns, ...allCount })
  updateCrossChainList(modifiedCrossChains)
}

export async function searchCrossChainData(searchVal: string, currentPage: number, pageSize: number, updateCrossChainList: any, updateTotalCount: any, isSakura:boolean = false) {
  const totalCount = await window.fetch(`${isSakura ? SERVICE_SAKURA_ADDRESS : SERVICE_ADDRESS}records/${searchVal}/count`, {
    method: 'GET',
  }).then((httpResponse) => httpResponse.json())

  const allCount = await window.fetch(`${isSakura ? SERVICE_SAKURA_ADDRESS : SERVICE_ADDRESS}statistic/total`, {
    method: 'GET',
  }).then((httpResponse) => httpResponse.json())

  const crossChains = await window.fetch(`${isSakura ? SERVICE_SAKURA_ADDRESS : SERVICE_ADDRESS}records/${searchVal}?pageNum=${currentPage}&pageSize=${pageSize}`, {
    method: 'GET',
  }).then((httpResponse) => httpResponse.json())

  const modifiedCrossChains = crossChains.map((txn: any) => {
    const burnTime = moment(txn.burn_time);
    const nowTime = moment(new Date());
    const burnDuration = moment.duration(nowTime.diff(burnTime));
    const burnSeconds = Math.round(burnDuration.asSeconds());
    const claimTime = moment(txn.claim_time ? txn.claim_time : new Date());
    const diffTime = claimTime.diff(burnTime);
    const claimDuration = moment.duration(diffTime);
    const claimSeconds = Math.round(moment.duration(nowTime.diff(claimTime)).asSeconds());
    return {
      requestTime: getRequestTime(txn.burn_time, burnSeconds),
      requestTimeByCalandar: burnTime.utc().format('YYYY-MM-DD HH:mm:ss'),
      duration: getDurationWithMinted(txn, diffTime, claimDuration),
      claimRequestTime: txn.claim_time ? getRequestTime(txn.claim_time, claimSeconds) : null,
      confirmations: burnSeconds > 300 ? '15/15' : null,
      ...txn
    };
  });

  updateTotalCount({ total_count: totalCount.count, ...allCount })
  updateCrossChainList(modifiedCrossChains)
}

