import React, {useCallback, useEffect, useState} from 'react'
import styled from 'styled-components';
import _ from 'lodash'
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import StepConnector from '@material-ui/core/StepConnector';
import StepSuccess from '../../assets/images/step_sku_success.svg'
import StepPending from '../../assets/images/step_sku_pending.svg'
import StepWaiting from '../../assets/images/step_waiting.svg'
import LinkIcon from '../../assets/images/link_icon.svg'
import { Text } from 'rebass'
import {getColorByStatus, getIconByChain, explorerUrl} from '../../utils/index'
import moment from "moment";
import { OPTIONS } from "../../constants";
import ClaimDialog from "../../components/ClaimDialog/index"
import {useAccountInfo} from "../../state/wallet/hooks";
import CopyText from "../../components/CopyText";

const Web3 = require('web3');

const StepContentWrapper = styled.div`
  min-width: fit-content;
  width: 500px;
  height: 117px;
  background: #F5F5F5;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px 32px;
`

const StepContentItem = styled.div`
  display: grid;
  grid-template-columns: 100px auto;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: rgba(55, 57, 74, 0.7);
  
  img {
    margin-left: 3px;
    cursor: pointer;
  }
  
  & > div {
    display: flex;
    align-items: center;
    img {
      margin-right: 25px;
    }
  }
`

const StepLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  
  img {
    width: 20px;
    height: 20px;
  }
  
  h4 {
    font-family: Roboto;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
    color: rgba(0, 24, 49, 0.7);
    margin: 0 7px;
  }
  
  span {
    font-family: Roboto;
    font-size: 14px;
    line-height: 16px;
  }
`

const ClaimBtn = styled.div`
  padding: 4px 12px;
  margin-left: 12px;
  background: #FFFFFF;
  color: #4BA870;
  border: 1px solid #4BA870;
  border-radius: 4px;
  font-family: Roboto;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @media (max-width: 576px){
    display: none;
  }
`

export default function DetailComponent(props: any): React.ReactElement {
    const {detail, handleClaim} = props
    const accountInfo = useAccountInfo();

    const [differBlock, setDifferBlock] = useState(0)
    const [openClaim, setOpenClaim] = useState(false)
    const [canClaim, setCanClaim] = useState(false)

    const option: any = _.find(OPTIONS, opt => opt.network.toLowerCase() === detail.from_chain.toLowerCase())
    const steps = detail.to_chain.toLowerCase() === 'clover' ? ['Burn', 'Mint', 'Claim'] : ['Burn', 'Mint'];
    const activeStep = detail.claim_time ? detail.to_chain.toLowerCase() === 'clover' ? 3 : 2 : detail.mint_time ? 2 : 1

    const Connector = withStyles({
        alternativeLabel: {
            top: 10,
            left: 'calc(-50% + 16px)',
            right: 'calc(50% + 16px)',
        },
        active: {
            '& $line': {
                borderColor: '#FF5995',
            },
        },
        completed: {
            '& $line': {
                borderColor: '#FF5995',
            },
        },
        line: {
            borderTopWidth: 3,
            borderRadius: 1,
        },
    })(StepConnector);
    const StepContentStyle = withStyles({
        root: {
            borderColor: '#FF5995'
        },
    })(StepContent);

    const showClaim = useCallback(async () => {
        try {
            if (accountInfo && accountInfo.address && window.clover) {
                const clvAccounts = await window.clover.request({ method: 'clover_getAccounts' })
                const currentAddr = _.find(clvAccounts, acc => acc[0] === accountInfo.address)
                setCanClaim(currentAddr[1] === detail.from_address)
            }
        } catch (e) {
            setCanClaim(false)
        }

    }, [accountInfo, detail.from_address])

    useEffect(() => {
        if ((detail.from_chain.toLowerCase() === 'bsc' || detail.from_chain.toLowerCase() === 'ethereum') && activeStep === 2) {
            showClaim()
        }
    }, [detail, showClaim, activeStep])

    useEffect(() => {
        if (detail.from_chain.toLowerCase() === 'bsc' || detail.from_chain.toLowerCase() === 'ethereum') {
            const loadBlockNumber = setInterval(() => {
                const web3 = new Web3(new Web3.providers.HttpProvider(option.rpcUrl))
                web3.eth.getBlockNumber().then((res: any) => {
                    setDifferBlock(res - detail.burn_block_no > 15 ? 15 : res - detail.burn_block_no)
                });
            }, 5000)
            return () => {
                clearInterval(loadBlockNumber)
            }
        }
    }, [option, detail, differBlock])

    useEffect(() => {
        if (detail.claim_time) {
            setOpenClaim(false)
        }
    }, [detail])

    const getConfirmations = () => {
        if (detail.confirmations) {
            return detail.confirmations
        }
        return `${differBlock}/15`
    }

    function getStepContent(step: number) {
        switch (step) {
            case 0:
                return (
                    <StepContentWrapper>
                        <StepContentItem>
                            <span>Txn Hash</span>
                            <Text color="#4BA870">
                                <CopyText content={detail.burn_tx_hash}>
                                    {detail.burn_tx_hash ? `${detail.burn_tx_hash.slice(0, 40)}...` : 'n/a'}
                                </CopyText>
                                <img onClick={() => detail.burn_tx_hash ? window.open(`${explorerUrl(detail.from_chain, 'hash',true)}${detail.burn_tx_hash}`) : {}} width={24} height={24} src={LinkIcon} alt=""/>
                            </Text>
                        </StepContentItem>
                        <StepContentItem>
                            <span>Block</span>
                            <div>
                                <CopyText content={detail.burn_block_no}>
                                    <Text color="#4BA870">{detail.burn_block_no??'n/a'}</Text>
                                </CopyText>
                                <img onClick={() => detail.burn_block_no ? window.open(`${explorerUrl(detail.from_chain, 'block',true)}${detail.burn_block_no}`) : {}} width={24} height={24} src={LinkIcon} alt=""/>
                                {(detail.from_chain.toLowerCase() === 'bsc' || detail.from_chain.toLowerCase() === 'ethereum') && <span>{getConfirmations()} Block Confirmations</span>}
                            </div>
                        </StepContentItem>
                        <StepContentItem>
                            <span>Timestamp</span>
                            <Text>
                                {detail.burn_time && moment(detail.burn_time).utc().format('YYYY-MM-DD HH:mm:ss')} (UTC)
                            </Text>
                        </StepContentItem>
                    </StepContentWrapper>
                );
            case 1:
                return null;
            case 2:
                return (
                    <StepContentWrapper>
                        <StepContentItem>
                            <span>Txn Hash</span>
                            <Text color="#4BA870">
                                <CopyText content={detail.claim_tx_hash}>
                                    {detail.claim_tx_hash ? `${detail.claim_tx_hash.slice(0, 40)}...` : 'n/a'}
                                </CopyText>
                            </Text>
                        </StepContentItem>
                        <StepContentItem>
                            <span>Block</span>
                            <div>
                                <CopyText content={detail.claim_block_no}>
                                    <Text color="#4BA870">{detail.claim_block_no??'n/a'}</Text>
                                </CopyText>
                                <img onClick={() => detail.claim_block_no ? window.open(`${explorerUrl(detail.to_chain, 'block')}${detail.claim_block_no}`) : {}} width={24} height={24} src={LinkIcon} alt=""/>
                            </div>
                        </StepContentItem>
                        <StepContentItem>
                            <span>Timestamp</span>
                            <Text>
                                {detail.claim_time && moment(detail.claim_time).utc().format('YYYY-MM-DD HH:mm:ss')} (UTC)
                            </Text>
                        </StepContentItem>
                    </StepContentWrapper>
                );
            default:
                return 'Unknown step';
        }
    }

    function getStepLabel(label: string) {
        switch (label) {
            case 'Burn':
                return (
                    <StepLabelWrapper>
                        <img src={getIconByChain(detail.from_chain,true)} alt=""/>
                        <h4>{label}</h4>
                        <span style={{color: getColorByStatus('Done')}}>Done</span>
                    </StepLabelWrapper>
                );
            case 'Mint':
                return (
                    <StepLabelWrapper>
                        <img src={getIconByChain(detail.to_chain,true)} alt=""/>
                        <h4>{label}</h4>
                        <span style={{color: getColorByStatus(activeStep === 1 ? 'Pending' : 'Finished')}}>{activeStep === 1 ? 'Pending' : 'Finished'}</span>
                        {
                          detail.from_chain === 'Clover' && detail.mint_tx_hash &&
                          <img
                            style={{cursor: 'pointer', marginLeft: '3px'}}
                            onClick={() => detail.mint_tx_hash ? window.open(`${explorerUrl(detail.to_chain, 'hash')}${detail.mint_tx_hash}`) : {}}
                            width={24}
                            height={24}
                            src={LinkIcon}
                            alt=""/>
                        }
                    </StepLabelWrapper>
                );
            case 'Claim':
                return (
                    <StepLabelWrapper>
                        <img src={getIconByChain(detail.to_chain,true)} alt=""/>
                        <h4>{label}</h4>
                        <span style={{color: getColorByStatus(activeStep === 3 ? 'Finished' : activeStep === 1 ? 'Waiting' : 'Pending')}}>{activeStep === 3 ? 'Finished' : activeStep === 1 ? 'Waiting' : 'Pending'}</span>
                        {activeStep === 2 && canClaim && <ClaimBtn onClick={() => setOpenClaim(true)}>Claim Now</ClaimBtn>}
                        {/* {<ClaimBtn onClick={() => setOpenClaim(true)}>Claim Now</ClaimBtn>} */}
                    </StepLabelWrapper>
                );
            default:
                return 'Unknown step';
        }
    }

    function StepIcon(props: any) {
        const { active, completed } = props;

        return (
            active ? <img width={25} height={25} src={StepPending} alt="" /> :
                <img style={{marginLeft: '5px'}} width={16} height={16} src={completed ? StepSuccess : StepWaiting} alt=""/>
        );
    }
    return (
        <div>
            <Stepper activeStep={activeStep} orientation="vertical" connector={<Connector />}>
                {steps.map((label, index) => (
                    <Step key={label} expanded={true}>
                        <StepLabel StepIconComponent={StepIcon}>{getStepLabel(label)}</StepLabel>
                        <StepContentStyle>
                            <div>{getStepContent(index)}</div>
                        </StepContentStyle>
                    </Step>
                ))}
            </Stepper>
            <ClaimDialog
                open={openClaim}
                handleClose={() => setOpenClaim(false)}
                handleClaim={handleClaim}
                option={option}
                txHash={detail.burn_tx_hash}
                toChain={detail.to_chain}
                id={detail.id}
                isSakura = {true}
            >
            </ClaimDialog>
        </div>
    );
}
