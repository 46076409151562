import React, {ReactElement, useState, useCallback, useEffect,useRef} from 'react';
import { web3FromAddress } from '@polkadot/extension-dapp';
import { stringToHex } from '@polkadot/util'
import styled from 'styled-components';
import {
  useAccountInfo,
  useCrossChainList,
  useTotalCount,
  useCrossChainListUpdate,
  useTotalCountUpdate
} from '../../state/wallet/hooks'
import { api } from '../../utils/apiUtils'
import { getCrossChainData, searchCrossChainData } from '../../utils/AccountUtils'
import Footer from '../../components/Footer/Footer';
import TotalCrossChain from '../../components/TotalCrossChain';
import SearchIcon from '../../assets/images/search_icon.svg'
import Delete from '../../assets/images/delete.png'
import { Text } from 'rebass'
import {shortenAddress, getIconByChain, getColorByStatus} from '../../utils/index'
import HoverUpIcon from "../../assets/images/hover_up_icon.svg";
import UpIcon from "../../assets/images/up_icon.svg";
import Clock from "../../assets/images/clock.png";
import Calendar from "../../assets/images/calendar.png";
import HoverDownIcon from "../../assets/images/hover_down_icon.svg";
import DownIcon from "../../assets/images/down_icon.svg";
import AddressLoading from "../../assets/images/address_loading.svg";
import TablePagination from '@material-ui/core/TablePagination';
import DetailComponent from "./detail";
import {SERVICE_ADDRESS} from "../../constants";
import CopyText from "../../components/CopyText";

const Web3 = require('web3');
const { decodeAddress } = require('@polkadot/util-crypto');

function useInterval(fun:any) {
  const myRef = useRef(()=>{});
  useEffect(() => {
    myRef.current = fun;
  }, [fun]);
  useEffect(() => {
    let id = setInterval(() => {
      if(myRef && myRef.current) {

        myRef.current();
      }
    }, 15000);
    return () => clearInterval(id);
  }, []);
}

const HomePageWrapper = styled.div`
  //max-width: 1200px;
  width: 100%;
`

const Header = styled.div`
  width: 100%;
  height: 313px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 576px){
    height: 207px;
  }
`

const TitleText = styled(Text)`
  @media(max-width: 576px) {
    display: none;
  }
`

const SearchWrapper = styled.div`
  background: #FFFFFF;
  border: 1px solid #D5DAE2;
  box-sizing: border-box;
  border-radius: 25px;
  height: 52px;
  width: 754px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 8px 36px rgba(0, 0, 0, 0.15);
  @media (max-width: 576px){
    width: 100%;
    margin: 40px 0;
  }
`

const SearchInput = styled.input`
  margin: 0 20px;
  height: 100%;
  border: none;
  outline: none;
  flex: 1;
`

const SearchBtn = styled.div`
  height: 46px;
  width: 46px;
  border-radius: 50%;
  cursor: pointer;
  background: #42c37b;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2px;
  &:hover {
    background: #21AA5E;
  }
`

const Content = styled.div`
  max-width: 1200px;
  padding: 0 40px;
  margin: 0 auto;
  @media (max-width: 767px){
    width: 100%;
  }
  @media (max-width: 576px){
    padding: 0 20px;
  }
`

const TableWrapper = styled.div`
  background: #FFFFFF;
  border-radius: 8px;
  width: 100%;
  padding: 24px 32px;
  border: 1px solid rgba(0,0,0,0.1);
  box-shadow: 0px 18px 34px rgb(0 0 0 / 4%);
  &.searchMode{
    margin-top: -50px;
  }
  @media (max-width: 576px){
    overflow-x: scroll;
  }
`

const TableContent = styled.div`
  @media (max-width: 576px){
    min-width: 1000px;
  }
`

const TableHeader = styled.div`
  background: #F5F5F5;
  width: 100%;
  padding: 12px;
  display: grid;
  grid-template-columns: 14% 14% 14% 15% 10% 18% 8% 7%;
  font-weight: 600;
  span {
    font-size: 14px;
  }
  & > span {
    font-family: Roboto;
    font-weight: bold;
    line-height: 18px;
    color: rgba(55, 57, 74, 0.6);
  }
`
const TableItem = styled.div`
  width: 100%;
  padding: 12px;
  height: 56px;
  display: grid;
  align-items:center;
  grid-template-columns: 14% 14% 14% 15% 10% 18% 8% 7%;
  border-bottom: 1px solid #E8EAEE;
  cursor: pointer;
  
  &:hover {
    background: #fafafa;
  }
  
  & > span {
    font-family: Roboto;
    font-size: 14px;
    line-height: 24px;
    color: #37394A;
  }
  
  & > div {
    display: flex;
    align-items: center;
    img {
      width: 20px;
      height: 20px;
      margin-right: 2px;
    }
    span {
      font-family: Roboto;
      font-size: 14px;
      line-height: 18px;
      text-align: right;
    }
  }
`

const DetailBtn = styled.img`
  width: 32px;
  height: 32px;
  cursor: pointer;
`
const TimeDiv = styled.div`
  display:flex;
  align-items: center;
  & > span {
    margin-right:4px;
  }
  & > img {
    &:hover{
      cursor: pointer;
    }
  }
`

export default function HomePage(props: any): ReactElement {
  const [currentIndex, setCurrentIndex] = useState(-1)
  const [hoverIndex, setHoverIndex] = useState(-1)
  const [searchStr, setSearchStr] = useState('')
  const [searchStr1, setSearchStr1] = useState('')
  const [isSearch, setIsSearch] = useState(false)
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [tableList, setTableList] = useState<any>();
  const [isClockMode,setClockMode] = useState(true);

  const accountInfo = useAccountInfo();
  const crossChainList = useCrossChainList();
  const totalCount = useTotalCount();

  const updateCrossChainList = useCrossChainListUpdate();
  const updateTotalCount = useTotalCountUpdate();

  const bytesToHex = (bytes: any) => {
    for (var hex = [], i = 0; i < bytes.length; i++) {
      hex.push((bytes[i] >>> 4).toString(16));
      hex.push((bytes[i] & 0xF).toString(16));
    }
    return '0x'+ hex.join("");
  }

  const doClaim = useCallback(async (network, dest, txnHash, signature, id) => {
    let injected
    try {
      injected = await web3FromAddress(accountInfo.address)
      if (injected.name !== 'clover') {
        return
      }
      api.getApi().setSigner(injected?.signer)
      const signedTransaction = api.getApi().tx.cloverClaims
        .claimElastic(network, dest, txnHash, signature);
      signedTransaction.send(async (params: any) => {
          // console.log('Transaction status:', params.status.type);

          if (params.status.isFinalized) {
            const blockHash = `${params.status.asFinalized}`;
            // console.log('Completed at block hash', params.status.asFinalized.toHex());
            let txIndex = -1;
            params.events.forEach((event: any/*{ phase, event: { data, method, section } }*/) => {
              if (`${event.event.section}.${event.event.method}` === 'balances.Transfer') {
                txIndex = event.phase.asApplyExtrinsic.toNumber();
              }
            });
            if (txIndex !== -1) {
              const claim = await window.fetch(`${SERVICE_ADDRESS}record/claimUpdate`, {
                method: 'POST',
                body: JSON.stringify({
                  id,
                  blockHash,
                  txIndex
                }),
              }).then(async (httpResponse) => httpResponse.json())
              if (claim.success || claim.message === 'Record has claimed.') {
                if (searchStr.trim() === '') {
                  await getCrossChainData(currentPage, pageSize, updateCrossChainList, updateTotalCount)
                } else {
                  await searchCrossChainData(searchStr, currentPage, pageSize, updateCrossChainList, updateTotalCount)
                }
              }
            }
          }
        })
    } catch (e) {
      console.log("error: "+e)
      return
    }
  }, [accountInfo,searchStr,currentPage, pageSize, updateCrossChainList, updateTotalCount])

  const doSignature = useCallback(async (toAddress, txnHash) => {
    let injected
    try {
      injected = await web3FromAddress(accountInfo.address)
      const claimAddress = bytesToHex(decodeAddress(toAddress))
      const sigMsg = `Pay CLVs to the Clover account:${claimAddress.slice(2)}${txnHash.slice(2)}`
      const signRaw = injected?.signer?.signRaw;
      if (!!signRaw) {
        const ret = await signRaw({data: stringToHex(sigMsg), address: accountInfo.address, type: 'payload'})
        return ret
      }
      return undefined
    } catch (e) {
      console.log("error: "+e)
      return undefined
    }
  }, [accountInfo])

  const findTxnInfoByTxnHash = useCallback(async (option, txnHash, toAddress, id) => {
    const web3 = new Web3(new Web3.providers.HttpProvider(option.rpcUrl))
    const receipt = await web3.eth.getTransactionReceipt(txnHash)

    if (receipt === null) {
      // console.log('transaction not found on erc chain')
    } else if (receipt.status === true) {
      const data = await api.getApi().query.cloverClaims.elasticClaims(option.network, txnHash);//Ethereum
      // console.log('data:', data.toHuman())

      if (data.isSome && data.value[2].isTrue) {
      } else if (data.isSome && data.value[2].isFalse) {
        const signature = await doSignature(toAddress, txnHash)
        if (!signature) {
          return
        }

        await doClaim(option.network, toAddress, txnHash, signature.signature, id)
        // console.log('not claim')
      } else {
        // console.log('something wrong')
      }
    } else {
      // console.log('something wrong on erc chain')
    }

  }, [doClaim, doSignature])

  useEffect(() => {
      if (searchStr1.trim() === '') {
        getCrossChainData(currentPage, pageSize, updateCrossChainList, updateTotalCount)
      } else {
        searchCrossChainData(searchStr1, currentPage, pageSize, updateCrossChainList, updateTotalCount)
      }
  }, [searchStr1,currentPage,pageSize, updateCrossChainList, updateTotalCount])

  useInterval(() => {
    if (searchStr1.trim() === '') {
      getCrossChainData(currentPage, pageSize, updateCrossChainList, updateTotalCount)
    } else {
      searchCrossChainData(searchStr1, currentPage, pageSize, updateCrossChainList, updateTotalCount)
    }
  });
  useEffect(() => {
    setTableList(crossChainList)
  }, [crossChainList])

  const tableHeader = [
    {
      name: 'From'
    },
    {
      name: 'To'
    },
    {
      name: 'Amount'
    },
    {
      name: 'Request time'
    },
    {
      name: 'Cross Fee'
    },
    {
      name: 'Duration'
    },
    {
      name: 'Status'
    },
    {
      name: 'Detail'
    }
  ]

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setCurrentIndex(-1)
    setCurrentPage(newPage);
    setTableList(crossChainList.slice(currentPage*pageSize, (currentPage + 1)*pageSize + 1))
  };

  const handleChangePageSize = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setCurrentIndex(-1)
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0);
    setTableList(crossChainList.slice(0, parseInt(event.target.value) > crossChainList.length ? crossChainList.length : parseInt(event.target.value)))
  };

  const handleSearch = async () => {
    setSearchStr1(searchStr);
    setIsSearch(searchStr.trim() === '' ? false:true);
    setCurrentPage(0);
    // if (queryTest.trim() === '') {
    //   await getCrossChainData(currentPage, pageSize, updateCrossChainList, updateTotalCount)
    // } else {
    //   await searchCrossChainData(queryTest, currentPage, pageSize, updateCrossChainList, updateTotalCount)
    // }
  }

  return (
    <HomePageWrapper>
      <Header>
        <TitleText fontSize={44} fontFamily="Roboto" fontWeight="bold" color="#000000" margin="50px 0 30px">CLV Cross-Chain Explorer</TitleText>
        <SearchWrapper>
          <SearchInput value={searchStr} onChange={(e) => {
            setSearchStr(e.target.value)
            if(!e.target.value) {
              setIsSearch(false);
              setSearchStr1('');
              setCurrentPage(0);
            }
          }} placeholder="Search by Address / Txn Hash "></SearchInput>
          {searchStr && <img src={Delete} alt="" style={{marginRight:'8px'}} onClick={async ()=>{
             setSearchStr('')
             setSearchStr1('');
             setIsSearch(false);
             setCurrentPage(0);
          }}/>}
          <SearchBtn onClick={() => handleSearch()}>
            <img width={15} height={16} src={SearchIcon} alt=""/>
          </SearchBtn>
        </SearchWrapper>
      </Header>
      <Content>
        {
          !isSearch &&  <TotalCrossChain totalCount={totalCount} isSakura={false}/>
        }
        <TableWrapper className={isSearch ? 'searchMode' : ''}>
          <TableContent>
            <TableHeader>
              {tableHeader.map(item => {
                return <TimeDiv key={item.name}>
                <span>
                  {item.name}
                </span>
                  {item.name === 'Request time' ? isClockMode ?
                      <img src={Clock} alt="" onClick={() => setClockMode(false)}/> : <img src={Calendar} alt=""  onClick={() => setClockMode(true)}/> : ''
                  }
                </TimeDiv>
              })}
            </TableHeader>
            {tableList && tableList.length > 0 && tableList.map((item: any, index: number) => {
              const isCloverPara =  item.to_chain === 'CloverPara' || item.from_chain === 'CloverPara';
              let status = isCloverPara ? item.burn_time && item.mint_time ? 'Success' : 'Pending' : item.claim_time ? 'Success' : 'Pending';
              if (item.chain_id && status === 'Pending') {
                if (item.mint_tx_hash || item.minted) {
                  status = 'Success'
                }
              }
              return <div key={`item_${index}`}>
                <TableItem
                  onClick={() => {
                    setCurrentIndex(index !== currentIndex ? index : -1)
                  }}
                >
                  <div>
                    <img src={getIconByChain(item.from_chain)} alt="" />
                    <CopyText content={item.from_address}>
                      <span>{shortenAddress(item.from_address)}</span>
                    </CopyText>
                  </div>
                  {item.to_address ?
                    <div>
                      <img src={getIconByChain(item.to_chain)} alt="" />
                      <CopyText content={item.to_address}>
                        <span>{shortenAddress(item.to_address)}</span>
                      </CopyText>
                    </div>
                    : <img width={30} src={AddressLoading} alt="" />
                  }
                  <span>{item.amount} CLV</span>
                  <span>{isClockMode ? item.requestTime : item.requestTimeByCalandar}</span>
                  <span>{item.fee} CLV</span>
                  <span>{item.duration}</span>
                  <span style={{ color: getColorByStatus(status) }}>{status}</span>
                  <DetailBtn
                    onMouseEnter={() => {
                      setHoverIndex(index)
                    }}
                    onMouseLeave={() => {
                      setHoverIndex(-1)
                    }}
                    src={index === currentIndex ? index === hoverIndex ? HoverUpIcon : UpIcon : index === hoverIndex ? HoverDownIcon : DownIcon}
                    alt=""
                  >
                  </DetailBtn>
                </TableItem>
                {index === currentIndex && (
                  <DetailComponent handleClaim={findTxnInfoByTxnHash} detail={item} isSakura={false}></DetailComponent>
                )}
              </div>
            })}
            <TablePagination
                component="div"
                count={totalCount.total_count??0}
                page={currentPage}
                onChangePage={handleChangePage}
                rowsPerPage={pageSize}
                onChangeRowsPerPage={handleChangePageSize}
                labelRowsPerPage=''
                nextIconButtonText="next"
                labelDisplayedRows={({ from, to, count }) => {
                  return `${Math.ceil(from/pageSize)}/${Math.ceil(count/pageSize)}`
                }}
            />
          </TableContent>
        </TableWrapper>
      </Content>
      <Footer />
    </HomePageWrapper>
  )
}

