import React, { useState } from 'react'
import './index.scss'
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '../../assets/images/dialog_close.svg'
import EthImage from '../../assets/images/eth.png'
import BscImage from '../../assets/images/bsc.png'
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import Radio from '@material-ui/core/Radio';
export default function RecoverDialog(props) {
    const { open, handleClose, recoverSubmit} = props;
    const [ txHash, setTxHash] = useState('');
    const [ disabledBtn, setDisabledBtn] = useState(true);
    const [selectedValue, setSelectedValue] = useState('Ethereum');
    const GreenRadio = withStyles({
        root: {
            color: green[400],
            '&$checked': {
                color: green[600],
            },
            '&:hover': {
                'background-color': 'initial'
            },
        },
        checked: {},
    })((props) => <Radio color="default" {...props} />);
    return (
        <Dialog className="recover-dialog" onClose={() => handleClose()} aria-labelledby="customized-dialog-title" open={open}>
            <div className="dialog-wrapper">
                <div>
                    <div className="dialog-header">
                        <h3>Transaction Recovery</h3>
                        <img width={16} height={16} src={CloseIcon} onClick={() => handleClose()} alt=""/>
                    </div>
                    <div className="radio-box">
                        <div className="radio-item item1">
                            <GreenRadio
                                checked={selectedValue === 'Ethereum'}
                                value="Ethereum"
                                color="secondary"
                                onChange={()=>setSelectedValue('Ethereum')}
                            />
                            <img src={EthImage} alt=""/>
                            <span> Ethereum </span>
                        </div>
                        <div className="radio-item">
                            <GreenRadio
                                checked={selectedValue === 'BSC'}
                                value="BSC"
                                onChange={()=>setSelectedValue('BSC')}
                            />
                            <img src={BscImage} alt=""/>
                            <span> BSC </span>
                        </div>
                    </div>
                    <div className="dialog-content">
                        <input
                            type="text"
                            placeholder="Input your trasaction hash"
                            onChange={(e) => {
                                setTxHash(e.target.value)
                                if(e.target.value.startsWith('0x') && e.target.value.length === 66){
                                    setDisabledBtn(false);
                                } else {
                                    setDisabledBtn(true)
                                }
                            }}
                        />
                    </div>
                    <button
                        className="claim-btn"
                        disabled={!txHash || disabledBtn}
                        onClick={()=>{recoverSubmit(txHash,selectedValue)}}
                    >
                      Submit Request
                    </button>
                </div>
            </div>
        </Dialog>
    )
}
