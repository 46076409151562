import { createAction } from '@reduxjs/toolkit'
import { AccountInfo } from './types';

export const accountList = createAction<{accountList: any}>('wallet/acountList')
export const accountInfo = createAction<{accountInfo: AccountInfo}>('wallet/acountInfo')
export const wrongNetwork = createAction<{wrongNetwork: boolean}>('wallet/wrongNetwork')
export const crossChainList = createAction<{crossChainList: any}>('wallet/crossChainList')
export const totalCount = createAction<{totalCount: number}>('wallet/totalCount')
export const skuCrossChainList = createAction<{skuCrossChainList: any}>('wallet/skuCrossChainList')
export const skuTotalCount = createAction<{skuTotalCount: number}>('wallet/skuTotalCount')
