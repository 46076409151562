import CloverIcon from '../assets/images/clover_icon.svg'
import SakuraIcon from '../assets/images/sku.svg'
import BscIcon from '../assets/images/bsc_icon.svg'
import EthIcon from '../assets/images/eth_icon.svg'
import moment, { Duration } from "moment";
import { BigNumber as BN } from "bignumber.js";
import { checkAddress } from '@polkadot/util-crypto';
import { IS_DEV } from '../constants/index'

export const checkAddressByFormat = (address: string, toChain: string) => {
  if (toChain.toLowerCase() === 'clover') {
    const result = checkAddress(address, 42)
    return result[0]
  }

  return address && address.startsWith('0x')
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function shortenAddress(address = '') {
  if (!address) return ''
  if (address.length < 11) {
    return address
  }

  return `${address.slice(0, 6)}...${address.slice(-4)}`
}

export const getIconByChain = (chain: string, isSakuraPage: boolean = false) => {
  const icon= isSakuraPage ? SakuraIcon : CloverIcon;
  switch (chain.toLowerCase()) {
    case 'clover':
      return icon;
    case 'ethereum':
      return EthIcon;
    case 'bsc':
      return BscIcon;
    default:
      return icon;
  }
}

export const getColorByStatus = (status: string) => {
  switch (status.toLowerCase()) {
    case 'success':
      return '#4BA870';
    case 'finished':
      return '#4BA870';
    case 'done':
      return '#4BA870';
    case 'pending':
      return '#2496FF';
    case 'failed':
      return '#F44F4B';
    case 'waiting':
      return 'rgba(77, 94, 111, 0.45)';
    default:
      return '#F44F4B';
  }
}

export const getRequestTime = (date: any, seconds: any) => {
  let newModifiedTime;
  if (seconds >= 1) {
    if (seconds === 1) {
      newModifiedTime = '1 sec ago';
    } else if (seconds < 60) {
      newModifiedTime = `${seconds} secs ago`;
    } else if (seconds < 3600) {
      const minutes = Math.round(seconds / 60);
      newModifiedTime = `${minutes} ${minutes === 1 ? 'min' : 'mins'} ago`;
    } else if (seconds < 86400) {
      const hours = Math.floor(seconds / 3600);
      let tempMin;
      if(hours * 3600 !== seconds) {
        tempMin = Math.round((seconds - hours * 3600) / 60);
      }
      newModifiedTime = `${hours} ${hours === 1 ? 'hr' : 'hrs'} ${tempMin? tempMin === 1 ? `${tempMin} min` : `${tempMin} mins` : ''} ago`;
    } else {
      const days = Math.floor(seconds / 86400);
      let tempHr;
      if(days * 86400 !== seconds) {
        tempHr = Math.round((seconds - days * 86400) / 3660);
      }
      newModifiedTime = `${days} ${days === 1 ? 'day' : 'days'} ${tempHr ? tempHr === 1 ? `${tempHr} hr` : `${tempHr} hrs` : ''} ago`;
    }
  } else {
    newModifiedTime = 'just now';
  }
  return newModifiedTime
}

export const getDuration = (seconds: Duration) => {
  let duration = '';
  if (seconds.hours()) {
    const hrNum =  `${seconds.hours() + (seconds.days() ? seconds.days() * 24 : 0)}`;
    duration += hrNum + ' ' + (Number(hrNum) > 1 ? 'hrs':'hr');
  }
  if (seconds.minutes()) {
    duration += (duration.length > 0 ? ' ' : '') + seconds.minutes() + ' ' + (seconds.minutes() > 1 ? 'mins' : 'min');
  }
  if (seconds.seconds()) {
    duration += (duration.length > 0 ? ' ' : '') + seconds.seconds() + ' ' + (seconds.seconds() > 1 ? 'secs' : 'sec');
  }
  return duration;
}

export const formatNumber = (num: any) => {
  if (!num) return 0
  return new BN(num).toFormat()
}

export const explorerUrl = (chain: string, str: string, isSku:boolean = false) => {
  const type = str === 'hash' ? chain.toLowerCase() === 'clover' ? 'extrinsic' : 'tx' : str
  switch (chain.toLowerCase()) {
    case 'clover':
      return IS_DEV ? `https://clover-testnet.subscan.io/${type}/` :  isSku ? `https://sakura.subscan.io/${type}/`:`https://clover.subscan.io/${type}/`
    case 'bsc':
      return IS_DEV ? `https://testnet.bscscan.com/${type}/` : `https://bscscan.com/${type}/`
    case 'ethereum':
      return IS_DEV ? `https://ropsten.etherscan.io/${type}/` : `https://etherscan.io/${type}/`
  }
}
