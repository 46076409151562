import React, {useState} from 'react'
import './index.scss'
import Dialog from '@material-ui/core/Dialog';
import LinkIcon from '../../assets/images/link_icon.svg'
import {explorerUrl} from "../../utils";
import EyeIcon from '../../assets/images/icon-eye.svg'
import { ethers } from "ethers";
import _ from 'lodash'

const ethUtil = require("ethereumjs-util");

export default function ClaimConfirmDialog(props) {
    const [showPrivateKey, setShowPrivateKey] = useState(false)
    const { open, handleClose, claim, option, txHash, clvAddress, privateKey,selectClover} = props;

    const onClaim = () => {
        claim(option, txHash, clvAddress, getValidPrivateKey())
        handleClose()
    }

    const getValidPrivateKey = () => {
      let pk = null
      try {
        if (_.indexOf(privateKey, ' ') >= 0) {
          const account = ethers.Wallet.fromMnemonic(privateKey);
          pk = account.privateKey
        } else {
          const wallet = new ethers.Wallet(privateKey);
          pk = privateKey
        }
      } catch (e) {
      }

      if (pk === null) {
        return pk
      }

      try {
        const msg = Buffer.from('signature');
        const msgHash = ethUtil.hashPersonalMessage(msg);
        ethUtil.ecsign(msgHash, new Buffer(pk.replace("0x", ""), 'hex'));
      } catch(e) {
        pk = null
      }
      
      return pk
    }

    const getPrivateKey = () => {
        let str = ''
        for(let i = 0; i < 40; i++) {
            str += '*'
        }
        return str
    }

    return (
        <Dialog className="confirm-dialog" onClose={() => handleClose()} aria-labelledby="customized-dialog-title" open={open}>
            <div className="dialog-wrapper">
                <div>
                    <div className="dialog-header">
                        <h3>Confirmation</h3>
                    </div>
                    <div className="dialog-content">
                        <div>
                            <span className="label">Txn Hash</span>
                            <div>
                                <span className="green-span">{txHash}</span>
                                <img onClick={() => txHash ? window.open(`${explorerUrl(option.network.toLowerCase(), 'hash')}${txHash}`) : {}} width={20} height={20} src={LinkIcon} alt=""/>
                            </div>
                        </div>
                        <div>
                            <span className="label">CLV Address</span>
                            <div>
                                <span className="green-span">{clvAddress}</span>
                                <img onClick={() => clvAddress ? window.open(`${explorerUrl('clover', 'address')}${clvAddress}`) : {}} width={20} height={20} src={LinkIcon} alt=""/>
                            </div>
                        </div>
                        <div>
                            <span className="label">Private Key</span>
                            <div>
                                <span style={{width: '300px', wordBreak: 'break-all'}}>{showPrivateKey ? privateKey : getPrivateKey()}</span>
                                <img onClick={() => {setShowPrivateKey(!showPrivateKey)}} width={20} height={20} src={EyeIcon} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="btns-wrapper">
                        <button
                            className={`${selectClover ? '' : 'isSakura'} claim-btn`}
                            onClick={onClaim}
                        >
                            Claim
                        </button>
                        <button
                            className="cancel-btn"
                            onClick={handleClose}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}
