import React, { useState, useCallback } from 'react'
import './index.scss'
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '../../assets/images/dialog_close.svg'
import EyeIcon from '../../assets/images/icon-eye.svg'
import EthImage from '../../assets/images/eth.png'
import BscImage from '../../assets/images/bsc.png'
import RemindIcon from '../../assets/images/remind_icon.svg'
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import Radio from '@material-ui/core/Radio';
import Input from '../../components/Input';
import { api } from '../../utils/apiUtils'
import { web3FromAddress } from '@polkadot/extension-dapp';
import { OPTIONS } from '../../constants';
import { ethers } from "ethers";
import _ from 'lodash'

const { checkAddress } = require('@polkadot/util-crypto');
const Web3 = require('web3');
const ethUtil = require("ethereumjs-util");

export default function ClaimClvDialog(props) {
    const { open, handleClose, claim, selectClover } = props;
    const [ txHash, setTxHash] = useState('');
    const [ clvAddress, setClvAddress] = useState('');
    const [ privateKey, setPrivateKey] = useState('');
    const [selectedValue, setSelectedValue] = useState('Ethereum');
    const [txHashError, setTxHashError] = useState('')
    const [clvAddressError, setClvAddressError] = useState('')
    const [privateKeyError, setPrivateKeyError] = useState('')
    const [showPrivateKey, setShowPrivateKey] = useState(false)
    const symbolName = selectClover ? 'CLV' : 'SKU'
    const GreenRadio = withStyles({
        root: {
            color: green[400],
            padding: '4px',
            '&$checked': {
                color: green[600],
            },
            '&:hover': {
                'background-color': 'initial'
            },
        },
        checked: {},
    })((props) => <Radio color="default" {...props} />);

    const isVaild = () => {
        if (txHash === '' || clvAddress === '' || privateKey === '' || txHashError !== '' || clvAddressError !== '' || privateKeyError !== '') {
            return false
        }
        return true
    }

    const getValidPrivateKey = async () => {
      let pk = null
      let wallet = null
      try {
        if (_.indexOf(privateKey, ' ') >= 0) {
          wallet = ethers.Wallet.fromMnemonic(privateKey);
          pk = wallet.privateKey
        } else {
          wallet = new ethers.Wallet(privateKey);
          pk = privateKey
        }
      } catch (e) {
      }

      if (pk === null) {
        return pk
      }

      try {
        const msg = Buffer.from('signature');
        const msgHash = ethUtil.hashPersonalMessage(msg);
        ethUtil.ecsign(msgHash, new Buffer(pk.replace("0x", ""), 'hex'));
      } catch(e) {
        pk = null
      }

      if (pk === null) {
        return pk
      }

      const option = OPTIONS.find(o => o.network === selectedValue)
      const web3 = new Web3(new Web3.providers.HttpProvider(option.rpcUrl))
      const recipe = await web3.eth.getTransactionReceipt(txHash)

      if (recipe === null) {
        throw new Error('Invalid transaction hash.')
      }

      if (recipe && recipe.from.toLowerCase() !== wallet.address.toLowerCase()) {
        pk = null
      }
      
      return pk
    }

    const onClaim = () => {
      const option = OPTIONS.find(o => o.network === selectedValue)
      getValidPrivateKey().then((pk) => {
        if (pk === null) {
          setPrivateKeyError('Invalid Private key')
          return
        }
        claim(option, txHash, clvAddress, privateKey)
      }).catch(e => {
        setTxHashError(e.message)
      })
    }

    return (
        <Dialog className="claim-clv-dialog" onClose={() => handleClose()} aria-labelledby="customized-dialog-title" open={open}>
            <div className="dialog-wrapper">
                <div>
                    <div className="dialog-header">
                        <h3>Claim {symbolName}</h3>
                        <img width={16} height={16} src={CloseIcon} onClick={() => handleClose()} alt=""/>
                    </div>
                    <div className="radio-box">
                        <div className="radio-item item1">
                            <GreenRadio
                                checked={selectedValue === 'Ethereum'}
                                value="Ethereum"
                                color="secondary"
                                onChange={()=>setSelectedValue('Ethereum')}
                            />
                            <img src={EthImage} alt=""/>
                            <span> Ethereum </span>
                        </div>
                        <div className="radio-item">
                            <GreenRadio
                                checked={selectedValue === 'BSC'}
                                value="BSC"
                                onChange={()=>setSelectedValue('BSC')}
                            />
                            <img src={BscImage} alt=""/>
                            <span> BSC </span>
                        </div>
                    </div>
                    <div className="dialog-content">
                        <Input
                            errorInfo={txHashError}
                            label='Transaction Hash'
                            placeHolder="Input your transaction hash"
                            value={txHash}
                            handleChange={(value) => {
                                setTxHash(value.trim())
                                if(value.trim().startsWith('0x') && value.length === 66){
                                    setTxHashError('')
                                } else {
                                    setTxHashError('Invalid transaction hash')
                                }
                            }}
                        />
                        <Input
                            styles={{marginTop: '20px'}}
                            errorInfo={clvAddressError}
                            label={`${symbolName} Address`}
                            placeHolder={`Target ${symbolName} address`}
                            value={clvAddress}
                            handleChange={(value) => {
                                setClvAddress(value.trim())
                                const result = checkAddress(value.trim(), 42)
                                if (result[0]) {
                                    setClvAddressError('')
                                } else {
                                    setClvAddressError(`Invalid ${symbolName} address`)
                                }
                            }}
                        />
                        <Input
                            styles={{marginTop: '20px'}}
                            type={showPrivateKey ? 'text' : 'password'}
                            errorInfo={privateKeyError}
                            label='Private Key'
                            placeHolder="Private key or seed words (12 - 24 words)"
                            value={privateKey}
                            handleChange={(value) => {
                                setPrivateKey(value.trim())
                                if(value.trim().length > 0){
                                    setPrivateKeyError('')
                                } else {
                                    setPrivateKeyError('Invalid Private key')
                                }
                            }}
                            rightContent={
                              <img 
                                onClick={() => {
                                  setShowPrivateKey(!showPrivateKey)
                                }}
                                style={{marginTop: '20px', cursor: 'pointer'}}
                                width={24}
                                height={24}
                                src={EyeIcon} 
                                alt=""/>}
                        />
                        <div className="claim-remind">
                            <div>
                                <img width={20} height={20} src={RemindIcon} alt=""/>
                                <span>Please make sure your {symbolName} address is correct. Otherwise you’ll lose your token forever.</span>
                            </div>
                            <div>
                                <img width={20} height={20} src={RemindIcon} alt=""/>
                                <span>CLV will never keep your private key, it will only be used locally.</span>
                            </div>
                        </div>
                    </div>
                    <button
                        className={`${selectClover ? '' : 'isSakura'} claim-btn`}
                        disabled={!isVaild()}
                        onClick={onClaim}
                    >
                      Claim
                    </button>
                </div>
            </div>
        </Dialog>
    )
}
