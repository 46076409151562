import React from 'react'
import './index.scss'

interface InputProps {
  styles?: any,
  className?: string,
  label?: string,
  icon?: React.ReactNode,
  width?: string,
  rightContent?: React.ReactNode,
  errorInfo?: string,
  height?: string,
  handleChange?: (val: string) => void,
  placeHolder?: string,
}

const Input = ({
  styles = {},
  errorInfo = undefined,
  rightContent = undefined,
  className = '',
  label = '',
  width = '100%',
  height = '52px',
  handleChange = () => {},
  placeHolder = '',
  ...inputProps
}: InputProps & Omit<React.HTMLProps<HTMLInputElement>, 'ref' | 'as'>) => {

  return (
    <div className="input-wrapper" style={styles}>
      {label !== '' && <div className="input-label">{label}</div>}
      <input
        className={errorInfo && 'input-error'}
        style={{
          width: width || '100%',
          height: height || '52px',
          paddingRight: rightContent ? '40px' : '0',
        }}
        placeholder={placeHolder}
        onChange={(e) => handleChange(e.target.value)}
        {...inputProps}
      />
      {
        rightContent && (
            <div className="right-content">
                {rightContent}
            </div>
        )
      }
      {errorInfo && <span className="error-info">{errorInfo}</span>}
    </div>
  )
}

export default Input
