import React from 'react'
import './index.scss'
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '../../assets/images/dialog_close.svg'
import Success from '../../assets/images/success.png'
import Faild from '../../assets/images/faild.png'
import Loading from '../../assets/images/loading_spinner.svg'
export default function ClaimStatusDialog(props) {
    const { open, status, errMsg, handleClose, selectClover} = props;

    const getIcon = () => {
      if (status === 'start') {
        return Loading
      } else if (status === 'fail') {
        return Faild
      } else {
        return Success
      }
    }

    const getText1 = () => {
      if (status === 'start') {
        return 'Claim submitted.'
      } else if (status === 'fail') {
        return 'Claim failed.'
      } else {
        return 'Claim Succeed.'
      }
    }

    const getText2 = () => {
      if (status === 'start') {
        return 'Waiting for block chain confirmation.'
      } else if (status === 'fail') {
        return errMsg + 'Please check and try again later.'
      } else {
        return 'Please check your account balance.'
      }
    }

    return (
        <Dialog className="recover-status-dialog" onClose={() => handleClose()} aria-labelledby="customized-dialog-title" open={open}>
            <div className="dialog-wrapper">
                <div>
                    <div className="dialog-header">
                        <h3>Claim Your CLV</h3>
                        <img width={16} height={16} src={CloseIcon} onClick={() => handleClose()} alt=""/>
                    </div>
                    <div className="dialog-content">
                      <img src={getIcon()} alt="" />
                      <div className="recovery-text-info">
                        <span>{getText1()}</span>
                        <span>{getText2()}</span>
                      </div>
                    </div>
                    <button
                        className= {`${selectClover ? 'isSakura' : ''} claim-btn`}
                        onClick={() => handleClose()}
                    >
                      Got it
                    </button>
                </div>
            </div>
        </Dialog>
    )
}
