import React, {ReactElement, useState} from 'react';
import styled from 'styled-components';
import {BlackTooltip} from "../BlackTooltip";
import copyToClipboard from "copy-to-clipboard";

const CopyWrapper = styled.div`
  cursor: pointer;
`

export default function CopyText(props: any): ReactElement {
    const { children, content } = props
    const [copyText, setCopyText] = useState('Click to copy')
    return (
        <BlackTooltip
            arrow
            title={copyText}
        >
            <CopyWrapper
                onMouseEnter={(e) => {
                    e.stopPropagation()
                    setCopyText('Click to copy')
                }}
                onMouseLeave={(e) => {
                    e.stopPropagation()
                }}
                onClick={(e) => {
                    e.stopPropagation()
                    copyToClipboard(content)
                    setCopyText('Copied!')
                }}
            >
                {children}
            </CopyWrapper>
        </BlackTooltip>
    )
}
