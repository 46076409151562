import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import CloverIcon from '../../assets/images/clover_icon.svg'
import SakuraIcon from '../../assets/images/sku.svg'
import BscIcon from '../../assets/images/bsc_icon.svg'
import EthIcon from '../../assets/images/eth_icon.svg'
import SwitchIcon from '../../assets/images/new-switch.svg'
import RemindIcon from '../../assets/images/remind_icon.svg'
import NewTxIcon from '../../assets/images/newTx.png'
import NewAddressIcon from '../../assets/images/newAddress.png'
import NewChainIcon from '../../assets/images/newChain.png'
import NewBalanceIcon from '../../assets/images/newBalance.png'
import NewRedTxIcon from '../../assets/images/newRedTx.png'
import NewRedAddressIcon from '../../assets/images/newRedAddress.png'
import NewRedChainIcon from '../../assets/images/newRedChain.png'
import NewRedBalanceIcon from '../../assets/images/newRedBalance.png'
import { Text } from 'rebass'
import { GrayTooltip } from "../BlackTooltip";
import { formatNumber } from '../../utils/index'
import {SERVICE_ADDRESS, SERVICE_SAKURA_ADDRESS} from "../../constants";

const TotalCrossChainWrapper = styled.div`
  width: 100%;
  position: relative;
  top: -50px;
  margin-bottom: -30px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0px 18px 34px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  padding: 50px 100px;
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 100px;
  grid-row-gap: 50px;
  @media(max-width: 576px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    grid-column-gap: 0;
    grid-row-gap: 0;
    padding: 0 20px;
    top: -75px;
    margin-bottom: -55px;
  }
`

const TotalItem = styled.div`
  display: flex;
  align-items: center;
  .item-icon{
    margin-right: 26px;
  }
  & > div {
    display: flex;
    flex-direction: column;
    & > span {
      font-size: 14px;
      line-height: 16px;
      font-family: Roboto;
      color: rgba(55, 57, 74, 0.5);
    }
  }
  @media(max-width: 576px) {
    width: 100%;
    padding: 20px 0;
    border-bottom: 1px solid #F4F4F4;
    & > img {
      width: 28px;
      height: 28px;
    }
    & > div {
      display: flex;
      flex-direction: column;
      & > div {
        font-size: 24px;
        line-height: 29px;
      }
      & > span {
        font-size: 14px;
        line-height: 16px;
        font-family: Roboto;
        color: rgba(55, 57, 74, 0.5);
      }
    }
    &:last-child {
      border: none;
    }
  }
`

const CrossChainFeeWrapper = styled.div`
  display: flex;
  margin-bottom: 12px;
`
const CrossChainFee = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  
  & > img {
    width: 20px;
    height: 20px;
  }
  
  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 4px;
    
    span {
      font-weight: normal;
      font-size: 12px;
    }
  }
`

const RemindImg = styled.img`
  margin-left: 10px;
  width: 24px;
  height: 24px;
  @media(max-width: 576px) {
    display: none;
  }
`

const TotalCrossChain = (props: { totalCount: any; isSakura?:boolean}) => {
    const { totalCount,isSakura} = props
    const [crossFee, setCrossFee] = useState({
        clv_bsc_fee: '-',
        bsc_clv_fee: '-',
        clv_eth_fee: '-',
        eth_clv_fee: '-',
    })
    const symbolName = isSakura ? 'SKU' : 'CLV';
    const getCrossFee = async () => {
        const fee = await window.fetch(`${ isSakura ? SERVICE_SAKURA_ADDRESS : SERVICE_ADDRESS}crossChainFee`, {
            method: 'GET',
        }).then(async (httpResponse) => httpResponse.json())
        setCrossFee(fee)
    }
    
    useEffect(() => {
        getCrossFee()
    }, [])
    return (
        <TotalCrossChainWrapper>
            <TotalItem>
                <img src={isSakura ? NewRedTxIcon:NewTxIcon} alt="" className="item-icon"/>
                <div>
                    <Text fontSize="38px" fontWeight="600" fontFamily="Roboto" color="#37394A" letterSpacing="-1px">
                        {formatNumber(totalCount.total_txns)}
                        <GrayTooltip
                            arrow
                            title={
                                <div>
                                  <div>
                                    Pending  {formatNumber(totalCount.pending_count)}<br/>
                                    Completed  {formatNumber(totalCount.complete_count)}<br/><br/>
                                  </div>
                                  <div>
                                    {symbolName}→BSC  {formatNumber(totalCount.clv_bsc_count)}<br/>
                                    BSC→{symbolName}  {formatNumber(totalCount.bsc_clv_count)}<br/>
                                    {symbolName}→ETH  {formatNumber(totalCount.clv_eth_count)}<br/>
                                    ETH→{symbolName}  {formatNumber(totalCount.eth_clv_count)}
                                  </div>
                                </div>
                            }
                            placement="right"
                        >
                            <RemindImg src={RemindIcon} alt="" />
                        </GrayTooltip>
                    </Text>
                    <span>Total cross-chain transactions</span>
                </div>
            </TotalItem>
            <TotalItem>
                <img src={isSakura ? NewRedBalanceIcon : NewBalanceIcon} className="item-icon" alt=""/>
                <div>
                    <Text fontSize="38px" fontWeight="600" fontFamily="Roboto" color="#37394A" letterSpacing="-1px">
                        {formatNumber(totalCount.total_amount)}<span style={{fontSize: '20px'}}> {symbolName}</span>
                        <GrayTooltip
                            arrow
                            title={
                                <div>
                                    {symbolName}→BSC  {formatNumber(totalCount.clv_bsc_amount)} {symbolName}<br/>
                                    BSC→{symbolName}  {formatNumber(totalCount.bsc_clv_amount)} {symbolName}<br/>
                                    {symbolName}→ETH  {formatNumber(totalCount.clv_eth_amount)} {symbolName}<br/>
                                    ETH→{symbolName}  {formatNumber(totalCount.eth_clv_amount)} {symbolName}
                                </div>
                            }
                            placement="right"
                        >
                            <RemindImg src={RemindIcon} alt="" />
                        </GrayTooltip>
                    </Text>
                    <span>Total cross-chain volume</span>
                </div>
            </TotalItem>
            <TotalItem>
                <img src={isSakura ? NewRedAddressIcon : NewAddressIcon} className="item-icon" alt=""/>
                <div>
                    <Text fontSize="38px" fontWeight="600" fontFamily="Roboto" color="#37394A" letterSpacing="-1px">{formatNumber(totalCount.total_address)}</Text>
                    <span>Total cross-chain address</span>
                </div>
            </TotalItem>
            <TotalItem>
                <img src={isSakura ? NewRedChainIcon : NewChainIcon} className="item-icon" alt=""/>
                <div>
                    <CrossChainFeeWrapper>
                        <CrossChainFee>
                            <img src={isSakura ? SakuraIcon : CloverIcon} alt=""/>
                            <div>
                                <Text lineHeight="14px" fontSize="16px" fontWeight="bold" fontFamily="Roboto" color="#37394A">{crossFee.bsc_clv_fee} <span>{symbolName}</span></Text>
                                <img width={60} height={20} src={SwitchIcon} alt=""/>
                                <Text lineHeight="14px" fontSize="16px" fontWeight="bold" fontFamily="Roboto" color="#37394A">{crossFee.clv_bsc_fee} <span>{symbolName}</span></Text>
                            </div>
                            <img src={BscIcon} alt=""/>
                        </CrossChainFee>
                        <CrossChainFee>
                            <img src={isSakura ? SakuraIcon : CloverIcon} alt=""/>
                            <div>
                                <Text lineHeight="14px" fontSize="16px" fontWeight="bold" fontFamily="Roboto" color="#37394A">{crossFee.eth_clv_fee} <span>{symbolName}</span></Text>
                                <img width={60} height={20} src={SwitchIcon} alt=""/>
                                <Text lineHeight="14px" fontSize="16px" fontWeight="bold" fontFamily="Roboto" color="#37394A">{crossFee.clv_eth_fee} <span>{symbolName}</span></Text>
                            </div>
                            <img src={EthIcon} alt=""/>
                        </CrossChainFee>
                    </CrossChainFeeWrapper>
                    <span>Current cross-chain fee</span>
                </div>
            </TotalItem>
        </TotalCrossChainWrapper>
    )
};

export default TotalCrossChain;
