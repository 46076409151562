import React from 'react';
import Social from '../Social/Social';
import { SOCIALS } from '../../constants';
import './index.scss'

const Footer = () => (

  <footer className="footer-wrapper">
    <div className="footer-container">
        &copy;2024&nbsp;CLV.finance All Rights Reserved.
      <Social
        items={SOCIALS}
      />
    </div>
  </footer>
);

export default Footer;
