export const IS_DEV = process.env.REACT_APP_ENV === 'dev'

export const originName = 'clv';
export const DOWNLOAD_WALLET_ERL = 'https://chrome.google.com/webstore/detail/clover-wallet/nhnkbkgjikgcigadomkphalanndcapjk'
export const EXPLORER_URL = IS_DEV ? 'https://clover-testnet.subscan.io/account/' : 'https://clover.subscan.io/account/'
export const SERVICE_ADDRESS = IS_DEV ? 'https://tx-api-dev.clover.finance/api/dashboard/' : 'https://tx-api.clover.finance/api/dashboard/'
export const SERVICE_SAKURA_ADDRESS = IS_DEV ? 'https://tx-sku-api-dev.clover.finance/api/dashboard/' : 'https://tx-sku-api.clover.finance/api/dashboard/'
export const CLOVER_SERVICE = IS_DEV ? 'wss://api.clover.finance/' : 'wss://api-ivy-elastic.clover.finance'
export const SAKURA_SERVICE = IS_DEV ? 'wss://sku-dev-api.clover.finance' : 'wss://rpc.sakura.clover.finance'

export const OPTIONS = [
    {
        network: 'BSC',
        rpcUrl: IS_DEV ? 'https://data-seed-prebsc-2-s3.binance.org:8545/' : 'https://bscrpc.com'
    },
    {
        network: 'Ethereum',
        rpcUrl: IS_DEV ? 'https://ropsten.infura.io/v3/bd6d2612b6c8462a99385dc5c89cfd41' : 'https://rpc.ankr.com/eth'
    }
]

export const SOCIAL_ICONS = {
    twitter: 'twitter',
    telegram: 'telegram',
    discord: 'discord',
    medium: 'medium',
}
export const SOCIALS = [
    {
        link: 'https://twitter.com/clover_finance/',
        icon: SOCIAL_ICONS.twitter,
    },
    {
        link: 'https://t.me/clvorg',
        icon: SOCIAL_ICONS.telegram,
    },
    {
        link: 'https://discord.com/invite/M6SxuXqMVB',
        icon: SOCIAL_ICONS.discord,
    },
    {
        link: 'https://medium.com/@clv_org',
        icon: SOCIAL_ICONS.medium,
    },
]
