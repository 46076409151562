import {withStyles} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

export const BlackTooltip = withStyles((theme) => ({
    arrow: {
        color: theme.palette.common.black,
    },
    tooltip: {
        borderRadius: '0',
        padding: '10px',
        color: '#fff',
        fontSize: '12px',
        lineHeight: '14px',
        fontFamily: 'Roboto',
        maxWidth: '270px',
        backgroundColor: theme.palette.common.black,
    },
}))(Tooltip);

export const GrayTooltip = withStyles((theme) => ({
    arrow: {
        color: '#393F51',
    },
    tooltip: {
        borderRadius: '8px',
        padding: '12px',
        color: '#fff',
        fontSize: '16px',
        fontFamily: 'Roboto',
        backgroundColor: '#393F51',
    },
}))(Tooltip);
