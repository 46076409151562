import { createReducer } from '@reduxjs/toolkit';
import { createAccountInfo } from '../../components/WalletComp/utils';
import { accountList, accountInfo, wrongNetwork, crossChainList, totalCount, skuCrossChainList, skuTotalCount } from './actions';
import { AccountInfo } from './types';

export interface WalletState {
  accountList: any,
  accountInfo: AccountInfo,
  wrongNetwork: boolean,
  crossChainList: any,
  skuCrossChainList: any,
  totalCount: any,
  skuTotalCount: any
}

const initialState: WalletState = {
  accountList: [],
  accountInfo: createAccountInfo('', '', '', [], []),
  wrongNetwork: false,
  crossChainList: [],
  skuCrossChainList: [],
  totalCount: {},
  skuTotalCount: {},
}

export default createReducer(initialState, builder =>
  builder
    .addCase(accountList, (state, action) => {
      const {accountList} = action.payload
      state.accountList = accountList
    })
    .addCase(accountInfo, (state, action) => {
      const {accountInfo} = action.payload
      state.accountInfo = accountInfo
    })
    .addCase(wrongNetwork, (state, action) => {
      const {wrongNetwork} = action.payload
      state.wrongNetwork = wrongNetwork
    })
    .addCase(crossChainList, (state, action) => {
      const {crossChainList} = action.payload
      state.crossChainList = crossChainList
    })
    .addCase(totalCount, (state, action) => {
      const {totalCount} = action.payload
      state.totalCount = totalCount
    })
    .addCase(skuCrossChainList, (state, action) => {
      const {skuCrossChainList} = action.payload
      state.skuCrossChainList = skuCrossChainList
    })
    .addCase(skuTotalCount, (state, action) => {
      const {skuTotalCount} = action.payload
      state.skuTotalCount = skuTotalCount
    })
);
