import React from 'react'
import './index.scss'
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '../../assets/images/dialog_close.svg'
import Success from '../../assets/images/success.png'
import Faild from '../../assets/images/faild.png'
export default function RecoverStatusDialog(props) {
    const { open, status, handleClose} = props;
    return (
        <Dialog className="recover-status-dialog" onClose={() => handleClose()} aria-labelledby="customized-dialog-title" open={open}>
            <div className="dialog-wrapper">
                <div>
                    <div className="dialog-header">
                        <h3>Transaction Recovery</h3>
                        <img width={16} height={16} src={CloseIcon} onClick={() => handleClose()} alt=""/>
                    </div>
                    <div className="dialog-content">
                      {
                          status === 'fail' ? <img src={Faild} alt="" /> : <img src={Success} alt="" />
                      }
                      <div className="recovery-text-info">
                          {
                            status === 'fail' ? <span>Hash does not exist.</span> : <span>Request submitted</span>
                          }
                          {
                            status === 'fail' ? <span>Please check and try again.</span> : <span>Please check your account after minutes.</span>
                          }
                      </div>
                    </div>
                    <button
                        className="claim-btn"
                        onClick={() => handleClose()}
                    >
                      Got it
                    </button>
                </div>
            </div>
        </Dialog>
    )
}
