import _ from 'lodash';
import { ApiPromise, WsProvider } from '@polkadot/api';
import { CLOVER_SERVICE, SAKURA_SERVICE} from '../constants'

/* eslint-disable  @typescript-eslint/no-explicit-any */
class ApiWrapper {
  private api: any
  private selectClover: any

  setApi(api: any) {
    this.api = api
  }

  setSelectType(selectClover: boolean) {
    this.selectClover = selectClover
  }

  getApi() {
    return this.api
  }

  getSelectType() {
    return this.selectClover
  }

  getTransFunction(transName: string) {
    switch(transName) {
      case 'unstakePoolShares': return this.api.tx.cloverDex.unstakePoolShares
      case 'stakePoolShares': return this.api.tx.cloverDex.stakePoolShares
      case 'withdrawRewards': return this.api.tx.cloverDex.withdrawRewards
      case 'addLiquidity': return this.api.tx.cloverDex.addLiquidity
      case 'withdrawLiquidity': return this.api.tx.cloverDex.withdrawLiquidity
      case 'contribute': return this.api.tx.crowdloan.contribute
      case 'refund': return this.api.tx.crowdloan.refund
      case 'close': return this.api.tx.crowdloan.dissolve
    }
    return null
  }
}

export const api = new ApiWrapper()

export const initApi = async (onInited: () => void, onConnected: () => void, onDisConnected: () => void, selectClover: boolean): Promise<void> => {
  if (!_.isEmpty(api.getApi()) && api.getSelectType() == selectClover) {
    return
  }
  const wsProvider = new WsProvider(selectClover ? CLOVER_SERVICE : SAKURA_SERVICE);

  const theApi = await ApiPromise.create({ provider: wsProvider, types: {
    AccountInfo: {
      nonce: "Index",
      consumers: "RefCount",
      providers: "RefCount",
      data: "AccountData",
    },
    Amount: "i128",
    Keys: "SessionKeys4",
    AmountOf: "Amount",
    Balance: "u128",
    Rate: "FixedU128",
    Ratio: "FixedU128",
    EcdsaSignature: "[u8; 65]",
    EvmAddress: "H160",
    EthereumTxHash: "H256",
    BridgeNetworks: {
      _enum: ["BSC", "Ethereum"],
    },
  } });
  api.setApi(theApi)
  api.setSelectType(selectClover)
  theApi.on('connected', () => {
    console.log('connected')
    onConnected()
  })
  theApi.on('disconnected', () => {
    console.log('disconnected')
    onDisConnected()
  })
  theApi.on('ready', () => {
    console.log('ready')
  })

  onInited()
}
