import React, { useEffect, useState, useCallback } from 'react'
import _ from 'lodash'
import styled from 'styled-components';
import { stringToHex } from '@polkadot/util'
import {getAddress, createEmptyAccountInfo} from './utils'
import { useAccountInfo, useAccountInfoUpdate } from '../../state/wallet/hooks';
import { supportedWalletTypes } from '../../utils/AccountUtils'
import WalletConnectComp from './walletConnectComp'
import Row from '../Row'

import './index.css'
import 'react-toastify/dist/ReactToastify.css';
import { useApiInited, useApiConnected } from '../../state/api/hooks';
import InfoModal from './InfoModal';
import { useWrongNetwork } from '../../state/wallet/hooks';
import {Text} from "rebass";
import Copy from "../../assets/images/new-copy.svg";
import { Tooltip } from "@material-ui/core";
import { ConnectButton } from "../../components/Button/index";
import Recover from "../../assets/images/recover.png";
import RecoverDialog from '../RecoverDialog';
import { SERVICE_ADDRESS } from '../../constants';
import RecoverStatusDialog from '../RecoverStatusDialog';
import ClaimClvDialog from '../ClaimClvDialog';
import ClaimConfirmDialog from '../ClaimConfirmDialog';
import ClaimStatusDialog from '../ClaimStatusDialog';
import { api } from '../../utils/apiUtils'

const { decodeAddress } = require('@polkadot/util-crypto');
const Web3 = require('web3');
const ethUtil = require("ethereumjs-util");

const ConnectButtonWrapper = styled.div`
  margin-right: 8px;
`

const WarningWrapper = styled(Row)`
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FA5050;
  align-items: center;
  border-radius: 4px;
  background: #FDEDED;
  padding: 6px 4px 5px 4px;
  cursor: pointer;
`

const WarningIcon = styled.div`
  height: 20px;
  font-size: 20px;
  line-height: 20px;
  margin-right: 4px;
`

const ConnectWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
`

const ClaimWrapper = styled.div<{
  selectClover: boolean,
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #42C37B;
  background-color: ${({selectClover}) => (selectClover ? '#42C37B': 'rgba(255, 255, 255, 0.2)')};
  height: 38px;
  border-radius: 20px;
  color: white;
  font-size: 14px;
  padding: 8px 20px;
  cursor: pointer;
`

const WarningInfoComp = (): React.ReactElement => {
  const [open, setOpen] = useState(false)

  const onClose = () => {
    setOpen(false)
  }

  const onClick = () => {
    setOpen(true)
  }
  return (
    <div>
      <WarningWrapper onClick={onClick}>
        <WarningIcon><i className={'fa fo-alert-octagon'}></i>
        </WarningIcon>Wrong Network
      </WarningWrapper>
      <InfoModal isOpen={open} onClose={onClose} title={'Wrong Network'} info={'Please connect to Clover network'}></InfoModal>
    </div>
    )
}

export default function WalletComp(props: any): React.ReactElement {
  const { handleConnectWallet, openSelectAccount, hasWallet,selectClover } = props
  const [copyText, setCopyText] = useState('Click to copy');
  const [statusText, setStatusText] = useState('');
  const [openRecoverDialog, setOpenRecoverDialog] = useState(false)
  const [openRecoverStatusDialog, setOpenRecoverStatusDialog] = useState(false)
  const [openClaimClv, setOpenClaimClv] = useState(false)
  const [openClaimConfirm, setOpenClaimConfirm] = useState(false)
  const [ option, setOption] = useState({});
  const [ txHash, setTxHash] = useState('');
  const [ clvAddress, setClvAddress] = useState('');
  const [ privateKey, setPrivateKey] = useState('');
  const [openClaimStatus, setOpenClaimStatus] = useState(false)
  const [claimStatus, setClaimStatus] = useState('waiting')
  const [claimErr, setClaimErr] = useState('')

  const inited = useApiInited()
  const apiConnected = useApiConnected()

  const myInfo = useAccountInfo()
  const updateAccountInfo = useAccountInfoUpdate()

  const wrongNetwork = useWrongNetwork()

  useEffect(() => {
    updateAccountInfo(createEmptyAccountInfo())
  }, [updateAccountInfo]);

  useEffect(() => {
    if (_.isEmpty(myInfo.walletName)) {
      return
    }

    const wallet = _.find(supportedWalletTypes, (w) => w.name === myInfo.walletName)
    if (_.isEmpty(wallet)) {
      return
    }

  }, [myInfo])

  const copyAddress = (addr: string) => {
    navigator.clipboard.writeText(addr)
    setCopyText('Copied!')
  }

  const recoverSubmit = async (txHash:string, selectNetwork:string) => {
    setOpenRecoverDialog(false);
    const result = await window.fetch(`${SERVICE_ADDRESS}record/recovery?hash=${txHash}&network=${selectNetwork}`, {
      method: 'GET',
    }).then(async (httpResponse) => await httpResponse.json())
    setStatusText(result.status);
    setOpenRecoverStatusDialog(true);
  }

  const onClaimFail = (errMsg: string) => {
    setClaimStatus('fail')
    setClaimErr(errMsg)
  }

  const onClaimSuccess = () => {
    setClaimStatus('success')
  }

  const onClaimStart = () => {
    setOpenClaimClv(false)
    setOpenClaimStatus(true)
    setClaimStatus('start')
  }

  const doClaim = useCallback(async (network, dest, txnHash, signature) => {
    try {
      const signedTransaction = api.getApi().tx.cloverClaims
        .claimElastic(network, dest, txnHash, signature);
      signedTransaction.send(async (params: any) => {
          // console.log('Transaction status:', params.status.type);

          if (params.status.isFinalized) {
            const blockHash = `${params.status.asFinalized}`;
            // console.log('Completed at block hash', params.status.asFinalized.toHex());
            let txIndex = -1;
            let success = false
            params.events.forEach((event: any/*{ phase, event: { data, method, section } }*/) => {
              if (`${event.event.section}.${event.event.method}` === 'cloverClaims.ElasticClaimed') {
                txIndex = event.phase.asApplyExtrinsic.toNumber();
              }
              if (event.event.method === 'ExtrinsicSuccess') {
                success = true
                onClaimSuccess()
              }
            });

            if (!success) {
              onClaimFail('')
            }
            
          }
        })
    } catch (e) {
      console.log("error: "+e)
      onClaimFail('Transfer error. ')
      return
    }
  }, [])

  const bytesToHex = (bytes: any) => {
    for (var hex = [], i = 0; i < bytes.length; i++) {
      hex.push((bytes[i] >>> 4).toString(16));
      hex.push((bytes[i] & 0xF).toString(16));
    }
    return '0x'+ hex.join("");
  }

  const doSignature = (toAddress: string, txnHash: string, privateKey: string) => {
    const claimAddress = bytesToHex(decodeAddress(toAddress))
    const signature = `Pay CLVs to the Clover account:${claimAddress.slice(2)}${txnHash.slice(2)}`
    const msg = Buffer.from(signature);
    const msgHash = ethUtil.hashPersonalMessage(msg);
    const sig = ethUtil.ecsign(msgHash, new Buffer(privateKey.replace("0x", ""), 'hex'));
    return ethUtil.toRpcSig(sig.v, sig.r, sig.s);
  }

  const findTxnInfoByTxnHash = useCallback(async (option, txnHash, toAddress, privateKey) => {
    onClaimStart()
    const web3 = new Web3(new Web3.providers.HttpProvider(option.rpcUrl))
    const receipt = await web3.eth.getTransactionReceipt(txnHash)

    if (receipt === null) {
      // console.log('transaction not found on erc chain')
      onClaimFail('Not Found. ')
    } else if (receipt.status === true) {
      const data = await api.getApi().query.cloverClaims.elasticClaims(option.network, txnHash);//Ethereum
      // console.log('data:', data.toHuman())

      if (data.isSome && data.value[2].isTrue) {
        onClaimSuccess()
      } else if (data.isSome && data.value[2].isFalse) {
        const signature = doSignature(toAddress, txnHash, privateKey)
        if (!signature) {
          return
        }

        await doClaim(option.network, toAddress, txnHash, signature)
        // console.log('not claim')
      } else {
        // console.log('something wrong')
        onClaimFail('Not confirm. ')
      }
    } else {
      // console.log('something wrong on erc chain')
      onClaimFail('Chain Error. ')
    }

  }, [doClaim, doSignature])

    return (
      <div className='right-head'>
        {
          ((inited && !apiConnected) || wrongNetwork)? <WarningInfoComp></WarningInfoComp> :
          myInfo.address === '' ?
            <ConnectButtonWrapper>
              <WalletConnectComp btnStyle='bottom' onButtonClick={handleConnectWallet} hasWallet={hasWallet} selectClover={selectClover}></WalletConnectComp>
            </ConnectButtonWrapper>
          : <ConnectWrapper onClick={openSelectAccount}>
              <ConnectButton selectClover={selectClover}>
                <Text marginRight="10px">{getAddress(myInfo.address)}</Text>
                <Tooltip title={copyText}>
                  <img
                      onMouseEnter={() => setCopyText('Click to copy')}
                      onClick={(e) => {
                        e.stopPropagation()
                        copyAddress(myInfo.address)
                      }}
                      src={Copy}
                      alt=""/>
                </Tooltip>
              </ConnectButton>
            </ConnectWrapper>
        }
        {/* {
          <div className="recover" onClick={()=>setOpenRecoverDialog(true)}>
              <img src={Recover} alt="" />
          </div>
        } */}
        {/* <ClaimWrapper onClick={() => setOpenClaimClv(true)} selectClover={selectClover}>
          Claim {selectClover ? 'CLV' : 'SKU'}
        </ClaimWrapper> */}
        {
          openClaimClv &&
          <ClaimClvDialog
            open = {openClaimClv}
            selectClover = {selectClover}
            handleClose={() => setOpenClaimClv(false)}
            claim={(option: object, txnHash: string, toAddress: string, privateKey: string) => {
              setOption(option)
              setTxHash(txnHash)
              setClvAddress(toAddress)
              setPrivateKey(privateKey)
              setOpenClaimClv(false)
              setOpenClaimConfirm(true)
            }}
          />
        }
        {
          openClaimConfirm && 
          <ClaimConfirmDialog
            open={openClaimConfirm}
            option={option}
            txHash={txHash}
            clvAddress={clvAddress}
            privateKey={privateKey}
            selectClover = {selectClover}
            handleClose={() => setOpenClaimConfirm(false)}
            claim={findTxnInfoByTxnHash}
          />
        }
        {
          openClaimStatus &&
          <ClaimStatusDialog
            open={openClaimStatus}
            status={claimStatus}
            selectClover = {selectClover}
            errMsg={claimErr}
            handleClose={() => setOpenClaimStatus(false)}/>
        }

        <RecoverDialog 
          open = {openRecoverDialog}
          handleClose={() => setOpenRecoverDialog(false)}
          recoverSubmit={recoverSubmit}
        >
        </RecoverDialog>
        <RecoverStatusDialog
          open = {openRecoverStatusDialog}
          status = {statusText}
          handleClose={() => setOpenRecoverStatusDialog(false)}
        >
        </RecoverStatusDialog>
      </div>
    );

}
