import React from 'react';
import './index.css'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components';
import { useApiInited, useApiConnected } from '../../state/api/hooks'
import { useWrongNetwork } from '../../state/wallet/hooks'
import { WalletType } from '../../utils/AccountUtils'
import { ConnectButton } from "../../components/Button/index";

export const Wrapper = styled.div`
  width: 100%;
`

interface WalletConnectCompProps {
  btnStyle?: string,
  onButtonClick?: () => void,
  onWalletClose?: (value?: WalletType) => void,
  hasWallet?: Boolean,
  selectClover?:boolean
}
export default function WalletConnectComp({
  btnStyle = 'bottom',
  onButtonClick,
  hasWallet,
  selectClover
}: WalletConnectCompProps): React.ReactElement {
  const { t } = useTranslation()

  const apiInited = useApiInited()
  const apiConnected = useApiConnected()

  const wrongNetwork = useWrongNetwork()

  const handleClick = () => {
    onButtonClick && onButtonClick()
  }

  return (
    <Wrapper>
      {
        btnStyle === 'bottom' && <ConnectButton onClick={handleClick} disabled={!apiInited || !apiConnected || wrongNetwork}  selectClover={selectClover} >{t('connectToWallet')}</ConnectButton>
      }
      {
        btnStyle === 'top' && <ConnectButton onClick={handleClick} disabled={!apiInited}  selectClover={selectClover} >{apiInited ? hasWallet ? t('selectAccount') : !hasWallet ? t('login') : t('connectToWallet') : t('connecting')}</ConnectButton>
      }
    </Wrapper>
  )

}
