import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Text } from 'rebass'
import WalletComp from "../WalletComp";
import Logo from '../../assets/images/logo.svg'
import GreyClover from '../../assets/images/grey-clover.png'
import GreySakura from '../../assets/images/grey-sakura.svg'
import PinkSakura from '../../assets/images/pink-sakura.png'
import { useHistory } from 'react-router-dom'
const HeaderWrapper = styled.div`
  width: 100%;
  padding: 0 40px;
  @media(max-width: 576px) {
    padding: 0 20px;
  }
`
const Header = styled.div`
  height: 54px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 40px;
  @media(max-width: 576px) {
    padding: 0;
  }
`;

const HeaderDiv = styled.div`
  display: flex;
  align-items: center;
  &.header-left{
    background: linear-gradient(0deg, #E4E4E4, #E4E4E4);
    box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.2), inset -6px -6px 10px rgba(255, 255, 255, 0.3);
    border-radius: 100px;
    width: 262px;
    height: 44px;
    padding: 0 4px;
    position: relative;
    cursor: pointer;
  }
  .slider-box{
    display: flex;
    align-items: center;
    background: linear-gradient(315deg, #E8E8E8 4.75%, rgba(255, 255, 255, 0.96) 4.76%, #FFFFFF 88.75%);
    box-shadow: 12px 12px 24px rgba(0, 0, 0, 0.02), 6px -6px 24px rgba(255, 255, 255, 0.3);
    border-radius: 40px;
    width: 118px;
    height: 36px;
    padding-left: 12px;
    cursor: pointer;
    position: absolute;
    transition: all .2s ease;
  }
  .bg-text{
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    .bg-text-left{
      display: flex;
      align-items: center;
      padding-left: 4px;
    }
    .bg-text-right{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-right: 4px;
    }
  }
  & > div {
    font-size: 28px;
  }
  
  &:last-child {
    @media(max-width: 576px) {
      display: none;
    }
  }
`;

export default function AuctionHeader(props: any): ReactElement {
  const { handleConnectWallet, openSelectAccount, hasWallet, changeSelectCloverStatus,selectClover} = props;
  const history = useHistory();
  const changeBodyBg = (status: boolean) => {
    if(status) {
      history.push('/')
      document.body.style.backgroundColor = '#FCFCFC';
    } else {
      history.push('/sakuraHomePage')
      document.body.style.backgroundColor = '#1B2733';
    }
  }
  return (
      <HeaderWrapper>
        <Header>
          <HeaderDiv className="header-left"onClick={()=>{
                changeBodyBg(!selectClover)
                changeSelectCloverStatus(!selectClover)}
              }>
            <div className="slider-box"style={{left: selectClover ? '4px': 'calc(100% - 124px)'}}>
              <img src={selectClover ? Logo : PinkSakura} width={20} height={20} alt=""/>
              <Text marginLeft="9px" lineHeight="36px" fontSize="22px" fontWeight="600" fontFamily="Roboto" color="rgba(21, 30, 38, 1)">{selectClover ? 'CLV':'sakura'}</Text>
            </div>
            <div className="bg-text">
              <div className="bg-text-left">
                <img src={GreyClover} width={20} height={20} alt=""/>
                <Text marginLeft="9px" lineHeight="36px" fontSize="22px" fontWeight="600" fontFamily="Roboto" color="#c0c0c0">CLV</Text>
              </div>
              <div className="bg-text-right">
                <img src={GreySakura}  width={20} height={20} alt=""/>
                <Text marginLeft="9px" lineHeight="36px" fontSize="22px" fontWeight="600" fontFamily="Roboto" color="#c0c0c0">sakura</Text>
              </div>
            </div>
          </HeaderDiv>
          <HeaderDiv>
            <WalletComp handleConnectWallet={handleConnectWallet} openSelectAccount={openSelectAccount} hasWallet={hasWallet} selectClover={selectClover}></WalletComp>
          </HeaderDiv>
        </Header>
      </HeaderWrapper>
  )
}
