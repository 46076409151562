import React, { useMemo } from 'react';
import cn from 'classnames';
import styles from './Social.module.scss';
import twitterIcon from '../../assets/images/twitter.svg';
import telegramIcon from '../../assets/images/telegram.svg';
import discordIcon from '../../assets/images/discord.svg';
import mediumIcon from '../../assets/images/medium.svg';
import { SOCIAL_ICONS } from '../../constants';

const iconsMap = {
  [`${SOCIAL_ICONS.twitter}`]: twitterIcon,
  [`${SOCIAL_ICONS.telegram}`]: telegramIcon,
  [`${SOCIAL_ICONS.discord}`]: discordIcon,
  [`${SOCIAL_ICONS.medium}`]: mediumIcon,
};

const Social = ({
  className,
  items = [],
}: {
  className?: string;
  items?: {
    link: string;
    icon: keyof typeof iconsMap;
  }[];
}) => {
  const data = useMemo(() => (
    items?.map(({ link, icon}) => {
      return (
        <li key={icon} className={styles.item}>
          <a
            className={styles.link}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
              <img src={iconsMap[icon]} alt=""/>
          </a>
        </li>
      );
    })
  ), [items]);
  if (data.length) {
    return (
      <div className={cn(styles.social, className)}>
        <ul className={styles.list}>{data}</ul>
      </div>
    );
  }
  return null;
};

export default Social;
